const initial = true;
const isLoaded = (state = initial, action)=>{
    switch(action.type){
        case 'IS_LOADED':
                return state = action.data;
        default: 
            return state;
    }
}

export default isLoaded;