import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import axios from "axios";
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { AnimateOnChange } from 'react-animation';
import { Helmet } from "react-helmet";
// import {getPostsBySlug} from "../lib/api";
import { useSelector, useDispatch } from 'react-redux';
import { pageData } from '../actions/actions';
import RatesApi from '../components/RatesApi';
import DealsMenu from '../components/DealsMenu';
import Breadcrumbs from '../components/Breadcrumbs';
import NewBanner from '../components/NewBanner';

const PageLoader = loadable(() => import("../components/PageLoader"));

function SingleDealDetails(props) {
  const [pageId, setPageId] = useState('');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState([]);
  const [rateShow, setRateShow] = useState([{ "value": "first-time-buyer", "label": "First Time Buyer Rate" }]);

  const dispatch = useDispatch();
  let allPageData = useSelector((state) => state.pageData);
  // console.log(props);
  const loadPage = async () => {
    let api = process.env.REACT_APP_API_URL;
    await axios.all([
      axios.get(api + 'wp-json/wp/v2/pages?_embed&slug=' + props.match.params.type + '-deals'),
    ])
      .then(axios.spread((result) => {
        // let getData = data;
        // data = result.data['0'];
        // console.log(result.data[0]);
        allPageData[props.pageId + '-deals'] = result.data['0'];
        // console.log(allPageData);
        dispatch(pageData(allPageData));
        setPageId(props.pageId);
        setIsLoaded(true);
        setData(result.data['0']);
        setRateShow(result.data['0']?.acf?.rates_show);
        if (result.data['0']._embedded['wp:term']) {
          setCategory(result.data['0']._embedded['wp:term']['0']);
        }
      }))
      .catch(error => {
        setPageId(props.pageId);
        setIsLoaded(false);
        // setData(error);
        setError(error);
        //For testing the old pages
        // setIsLoaded(true);
        // setData(true);
      });
  };
  useEffect(() => {
    // setPageId(props.pageId);
    window.scrollTo(0, 0);
    // console.log(allPageData[props.pageId + '-deals']?.acf?.rates_show);
    if (allPageData[props.pageId + '-deals']) {
      setData(allPageData[props.pageId + '-deals']);
      setPageId(props.pageId);
      setIsLoaded(true);
      // setData(getData);
      setRateShow(allPageData[props.pageId + '-deals']?.acf?.rates_show);
      setCategory(allPageData[props.pageId + '-deals']['categories']);
    } else {
      // console.log(props.pageId);
      loadPage();
    }
    const interval = setTimeout(() => {
      loadPage();
    }, 5000);
    return () => {
      clearTimeout(interval);
    };
  }, [props.pageId]);
  let hash = '';
  if (props.hash) {
    hash = props.hash;
  }
  let pageType = props.match.params.type;
  if (!pageType) {
    pageType = props.type;
  }
  let faqsData = useSelector((state) => state.faqsData);
  let servicesName = useSelector((state) => state.servicesName);
  let themeColor = '';
  if (servicesName[pageType]) {
    // themeColor = hexToRGB(servicesName[pageType].services.themeColor, servicesName[pageType].services.themeOpacity);
    themeColor = servicesName[pageType].services.themeColor;
  }
  let faqs = faqsData[pageType];
  // console.log(allPageData);
  // console.log(props.breadcrumbs, faqs);
  // console.log(window.location);
  // console.log(data?.acf);
  if (error) {
    return (<div>Error: {error.message}</div>);
  } else if (!isLoaded) {
    return (
      <PageLoader />
    );
  } else if (isLoaded && data) {
    // let categoryData = category;
    return (
      <div className="main-content deals-details-page-container" style={{ "--theme-color": themeColor }}>
        {data.acf &&
          <Helmet>
            <meta charSet="utf-8" />
            {/* {(data.acf.meta_title)?<title>{data.acf.meta_title}</title>:<title>{data.title.rendered} - Marianna Financial Services</title>} */}
            {(data.acf.meta_title) ? <title>{data.acf.meta_title}</title> : <title>Marianna Financial Services - For Expert Mortgage Advise in London</title>}
            {/* <link rel="canonical" href={`${window.location.href}`} /> */}
            <link rel="canonical" href={`https://mariannafs.co.uk${window.location.pathname}`} />
            {/* <link rel="canonical" href="https://mariannafs.co.uk/" /> */}
            {(data.acf.meta_description) ? <meta name="description" content={data.acf.meta_description} /> : <meta name="description" content={`${data.title.rendered} We offer free mortgage advise for your mortgages. With excellent customer rating 4.9/5 get in touch to see how can we help.`} />}
            {(data.acf.meta_keywords) ? <meta name="keywords" content={data.acf.meta_keywords} /> : <meta name="keywords" content={``} />}
          </Helmet>
        }
        {props.breadcrumbs && faqs?.length > 0 &&
          <Breadcrumbs pageId={pageType} title={faqs[0].post_title} pageUrl='/services' active="deals" />
        }
        {data?.acf?.banner_content &&
          <div className='mt-4'>
            <NewBanner heading={data?.acf?.banner_content} noBannerImage={true} noRatingsSection={true} />
            {/* <div className="page-header mt-51 mb-5" dangerouslySetInnerHTML={{ __html: data.acf.banner_content }}></div> */}
          </div>
        }
        <div className="container-fluid">
          <div className="container py-5 mt-2">
            <div className="page-header">
              {
                faqs &&
                <h2 className="pb-3 mb-0 text-center dark-text">{faqs[0].post_title} Deals</h2>
              }
              <RatesApi showRates={rateShow} />
            </div>
            {data?.acf?.content_section_1 &&
              <>
                <div className="page-header mt-5 mb-5" dangerouslySetInnerHTML={{ __html: data.acf.content_section_1 }}></div>
              </>
            }
            {/* <DealsMenu currentFaq={pageType} rateURL="compare-best-rates" /> */}
            {data?.acf?.content_section_2 &&
              <>
                <div className="page-header mt-5 mb-5" dangerouslySetInnerHTML={{ __html: data.acf.content_section_2 }}></div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}
// }
export default SingleDealDetails;
