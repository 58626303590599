export const container = {
  hidden: {},
  visible: {
    // opacity: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.9
    }
  }
};
export const itemWork = {
  hidden: { x: 500, opacity: 0 },
  visible: {
    x: 0, opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.3
    }
  }
};
export const boxVariant = {
  hidden: {
    x: "-100vw", //move out of the site
  },
  visible: {
    x: 0, // bring it back to nrmal
    transition: {
      // delay: 0.5,
      when: "beforeChildren", //use this instead of delay
      staggerChildren: 0.1, //apply stagger on the parent tag
    },
  },
};
export const listVariant = {
  hidden: {
    y: -10, //move out of the site
    opacity: 0,
    transition: { duration: 2 }
  },
  visible: {
    y: 0, // bring it back to nrmal
    opacity: 1,
  },
};