import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function Borrow(props){
  const [income1, setIncome1] = useState(5000); 
  const [income2, setIncome2] = useState(0); 
  const [result, setResult] = useState(0); 
  function handleChange(e){
    let value;
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      if(e.target.name==="income1"){
        setIncome1(parseFloat(value));
      }
      if(e.target.name==="income2"){
        setIncome2(parseFloat(value));
      }
    }
  }
  function calculate(){
    let temp = [];
    let i1 = income1 ? income1 : 0; 
    let i2 = income2 ? income2 : 0; 
    temp = AddComma(((parseInt(i1) + parseInt(i2))*5).toFixed(2));
    setResult(temp);
  }
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [income1, income2]);
  return (
    <>
      <div className="calculator-wrapper my-31">
        <div className="range-container">
          <h4 className="text-center">See how much you can borrow</h4>
          <div className="text-center d-none">Calculate your monthly mortgage payments for both repayment and interest-only mortgages.</div>
          <div className="new-label-input">
            <div className="option-label d-flex">
              <div className="option-label-text ml-2 px-1">Applicant 1 income (£):</div>
              <div className="range-value-container px-1">
                £ <span className="range-value">{AddComma(income1)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="text" name="income1"
                value={income1} onChange={handleChange} />
            </div>
            <div className="option-label d-flex">
              <div className="option-label-text ml-2 px-1">Applicant 2 income (£):</div>
              <div className="range-value-container px-1">
                <span className="range-value">{AddComma(income2)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="text" name="income2"
                value={income2} onChange={handleChange} /> 
            </div>
          </div>
          <div>Result : £{result}</div>
        </div>
      </div>
    </>
  );
}
export default Borrow;