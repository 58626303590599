const initial = [
    {
        "id": "cG9zdDoxMQ==",
        "label": "Home",
        "parentId": null,
        "path": "/home/",
        "title": "home",
        "target": null,
        "cssClasses": [],
        "key": "cG9zdDoxMQ==",
        "connectedNode": {
            "node": {
                "uri": "/home/"
            }
        },
        "children": []
    },
    {
        "id": "cG9zdDoxMw==",
        "label": "Services",
        "parentId": null,
        "path": "#",
        "title": "services",
        "target": null,
        "cssClasses": [],
        "key": "cG9zdDoxMw==",
        "connectedNode": null,
        "children": [
            {
                "id": "cG9zdDo2Ng==",
                "label": "First Time Buyer",
                "parentId": "cG9zdDoxMw==",
                "path": "/first-time-buyer/",
                "title": "first-time-buyer",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2Ng==",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyer/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDo2NQ==",
                "label": "Remortgage",
                "parentId": "cG9zdDoxMw==",
                "path": "/remortgaging/",
                "title": "remortgaging",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2NQ==",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgaging/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDo2NA==",
                "label": "Buy to let",
                "parentId": "cG9zdDoxMw==",
                "path": "/buy-to-let/",
                "title": "buy-to-let",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2NA==",
                "connectedNode": {
                    "node": {
                        "uri": "/buy-to-let/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDo5MA==",
                "label": "Home Mover",
                "parentId": "cG9zdDoxMw==",
                "path": "/home-mover/",
                "title": "home-mover",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo5MA==",
                "connectedNode": {
                    "node": {
                        "uri": "/home-mover/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDo5Mw==",
                "label": "Insurance",
                "parentId": "cG9zdDoxMw==",
                "path": "/protection-needs/",
                "title": "protection-needs",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo5Mw==",
                "connectedNode": {
                    "node": {
                        "uri": "/protection-needs/"
                    }
                },
                "children": []
            }
        ]
    },
    {
        "id": "cG9zdDoxMDE2",
        "label": "Tools",
        "parentId": null,
        "path": "#",
        "title": "tools",
        "target": null,
        "cssClasses": [],
        "key": "cG9zdDoxMDE2",
        "connectedNode": null,
        "children": [
            {
                "id": "cG9zdDoxMDE0",
                "label": "Mortgage Rates",
                "parentId": "cG9zdDoxMDE2",
                "path": "/rates/",
                "title": "rates",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMDE0",
                "connectedNode": {
                    "node": {
                        "uri": "/rates/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDoxMDE3",
                "label": "Mortgage Calculators",
                "parentId": "cG9zdDoxMDE2",
                "path": "/calculators/",
                "title": "calculators",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMDE3",
                "connectedNode": {
                    "node": {
                        "uri": "/calculators/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDoxMDE4",
                "label": "Mortgage Guides",
                "parentId": "cG9zdDoxMDE2",
                "path": "#",
                "title": "mortgage-guides",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMDE4",
                "connectedNode": null,
                "children": []
            },
            {
                "id": "cG9zdDozNDkz",
                "label": "Mortgage FAQs",
                "parentId": "cG9zdDoxMDE2",
                "path": "/mortgage-faqs/",
                "title": "mortgage-faqs",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDkz",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-faqs/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDoyMjc2",
                "label": "My Local Mortgage Broker",
                "parentId": "cG9zdDoxMDE2",
                "path": "/local-mortgage-broker/",
                "title": "mortgage-broker-near-me",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMjc2",
                "connectedNode": {
                    "node": {
                        "uri": "/local-mortgage-broker/"
                    }
                },
                "children": []
            }
        ]
    },
    {
        "id": "cG9zdDoxMTk1",
        "label": "More",
        "parentId": null,
        "path": "#",
        "title": "more",
        "target": null,
        "cssClasses": [],
        "key": "cG9zdDoxMTk1",
        "connectedNode": null,
        "children": [
            {
                "id": "cG9zdDoxMzQ=",
                "label": "About Us",
                "parentId": "cG9zdDoxMTk1",
                "path": "/about-us/",
                "title": "about-us",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMzQ=",
                "connectedNode": {
                    "node": {
                        "uri": "/about-us/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDo3NDg=",
                "label": "Contact Us",
                "parentId": "cG9zdDoxMTk1",
                "path": "/contact/",
                "title": "contact",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo3NDg=",
                "connectedNode": {
                    "node": {
                        "uri": "/contact/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDoxMjEx",
                "label": "Client Feedback",
                "parentId": "cG9zdDoxMTk1",
                "path": "/client-reviews/",
                "title": "client-reviews",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMjEx",
                "connectedNode": {
                    "node": {
                        "uri": "/client-reviews/"
                    }
                },
                "children": []
            },
            {
                "id": "cG9zdDoxMjc2",
                "label": "Join Us",
                "parentId": "cG9zdDoxMTk1",
                "path": "/join-us/",
                "title": "join-us",
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMjc2",
                "connectedNode": {
                    "node": {
                        "uri": "/join-us/"
                    }
                },
                "children": []
            }
        ]
    },
    {
        "id": "cG9zdDo5NjU=",
        "label": "Client  Login",
        "parentId": null,
        "path": "https://mariannafs.myfinance-hub.co.uk/login/",
        "title": "client-login",
        "target": "_blank",
        "cssClasses": [],
        "key": "cG9zdDo5NjU=",
        "connectedNode": null,
        "children": []
    }
];
const mainMenu = (state = initial, action)=>{
    switch(action.type){
        case 'MAINMENU':
                return state = action.data;
        default: 
            return state;
    }
}

export default mainMenu;