const initial = [
    {
        "query_id": "q1",
        "query": "How can we help?",
        "options": {
            "remortgage": {
                "text": "Remortgage",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q2"
            },
            "purchase": {
                "text": "Purchase",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q32"
            }
        }
    },
    {
        "query_id": "q2",
        "query": "How can we help?",
        "options": {
            "buy-to-let": {
                "text": "Buy to Let",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q3"
            },
            "residential": {
                "text": "Residential",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q18"
            }
        }
    },
    {
        "query_id": "q3",
        "query": "Why are you Remortgaging?",
        "options": {
            "to-save-money": {
                "text": "To save money",
                "img": "save-money.png",
                "col_width": "6",
                "next_id": "q4"
            },
            "to-raise-money": {
                "text": "To raise Money",
                "img": "raise-money.png",
                "col_width": "6",
                "next_id": "q4"
            }
        }
    },
    {
        "query_id": "q4",
        "query": "Tell us more about your existing mortgage.",
        "input_options": {
            "01": {
                "input_query": "What is the Value of your current home?",
                "range_from": "50000",
                "range_to": "1000000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "input_query": "What is the Outstanding Mortgage?",
                "range_from": "21000",
                "range_to": "1000000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "03": {
                "input_query": "LTV",
                "value_01": "01",
                "value_02": "02",
                "input_type": "calculator"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q107",
            "button_text": "Show Rates"
        }
    },
    {
        "query_id": "q5",
        "query": "More about your Mortgage",
        "input_options": {
            "02": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "2",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q6",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q6",
        "query": "Do you have good credit history?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q7"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q7"
            }
        }
    },
    {
        "query_id": "q7",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q8"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q8"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q8"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q8"
            }
        }
    },
    {
        "query_id": "q8",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q99",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q9",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q10"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q11"
            }
        }
    },
    {
        "query_id": "q10",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay? ",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q11",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q11",
        "query": "What type of mortgage you prefer ? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q12"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q12"
            }
        }
    },
    {
        "query_id": "q12",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q13"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q13"
            }
        }
    },
    {
        "query_id": "q13",
        "query": "What type of property is it ?",
        "options": {
            "flat": {
                "text": "Flat",
                "img": "flat.png",
                "col_width": "6",
                "next_id": "q14"
            },
            "house": {
                "text": "House",
                "img": "house.png",
                "col_width": "6",
                "next_id": "q14"
            }
        }
    },
    {
        "query_id": "q14",
        "query": "Is it new build ?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q15"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q15"
            }
        }
    },
    {
        "query_id": "q15",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "When was property build?",
                "range_from": "1900",
                "range_to": "2020",
                "range_unit": "",
                "unit_type": "year",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q16",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q16",
        "query": "Is your property freehold or leasehold?",
        "options": {
            "freehold": {
                "text": "Freehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q17"
            },
            "leasehold": {
                "text": "Leasehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q17"
            }
        }
    },
    {
        "query_id": "q17",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many bedrooms?",
                "range_from": "1",
                "range_to": "6",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "bedrooms",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "Let’s find you most suitable mortgage.",
            "next_id": "submit",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q18",
        "query": "Why are you Remortgaging?",
        "options": {
            "to-save-money": {
                "text": "To save money",
                "img": "save-money.png",
                "col_width": "6",
                "next_id": "q19"
            },
            "to-raise-money": {
                "text": "To raise Money",
                "img": "raise-money.png",
                "col_width": "6",
                "next_id": "q19"
            }
        }
    },
    {
        "query_id": "q19",
        "query": "Tell us more about your existing mortgage.",
        "input_options": {
            "02": {
                "input_query": "What is the Outstanding Mortgage?",
                "range_from": "21000",
                "range_to": "1000000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "03": {
                "input_query": "LTV",
                "value_01": "01",
                "value_02": "02",
                "input_type": "calculator"
            },
            "01": {
                "input_query": "What is the Value of your current home?",
                "range_from": "50000",
                "range_to": "1000000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q108",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q20",
        "query": "Do you have good credit history?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q21"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q21"
            }
        }
    },
    {
        "query_id": "q21",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "2",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q22",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q22",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q23"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q23"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q23"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q23"
            }
        }
    },
    {
        "query_id": "q23",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q103",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q24",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q25"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q26"
            }
        }
    },
    {
        "query_id": "q25",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay?",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q26",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q26",
        "query": "What type of mortgage you prefer ? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q27"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q27"
            }
        }
    },
    {
        "query_id": "q27",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q28"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q28"
            }
        }
    },
    {
        "query_id": "q28",
        "query": "What type of property is it?",
        "options": {
            "flat": {
                "text": "Flat",
                "img": "flat.png",
                "col_width": "6",
                "next_id": "q29"
            },
            "house": {
                "text": "House",
                "img": "house.png",
                "col_width": "6",
                "next_id": "q29"
            }
        }
    },
    {
        "query_id": "q29",
        "query": "Is it new build?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q30"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q30"
            }
        }
    },
    {
        "query_id": "q30",
        "query": "Tell us more about your purchase.",
        "input_options": {
            "01": {
                "input_query": "When was property build?",
                "range_from": "1900",
                "range_to": "2020",
                "range_unit": "",
                "unit_type": "year",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "option_id": "q96_02",
                "input_query": "Is your property freehold or leasehold?",
                "input_type": "radio",
                "options": {
                    "freehold": {
                        "text": "Freehold",
                        "col_width": "6"
                    },
                    "leasehold": {
                        "text": "Leasehold",
                        "col_width": "6"
                    }
                }
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q31",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q31",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many bedrooms?",
                "range_from": "1",
                "range_to": "6",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "bedrooms",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "Let’s find you most suitable mortgage.",
            "next_id": "submit",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q32",
        "query": "Tell us more about your purchase.",
        "options": {
            "buy-to-let": {
                "text": "Buy to Let",
                "img": "buy-to-let.png",
                "col_width": "6",
                "next_id": "q33"
            },
            "residential": {
                "text": "Residential",
                "img": "residential.png",
                "col_width": "6",
                "next_id": "q52"
            }
        }
    },
    {
        "query_id": "q33",
        "query": "Have you found a property yet?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q34"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q45"
            }
        }
    },
    {
        "query_id": "q34",
        "query": "Tell us more about your property.",
        "input_options": {
            "01": {
                "input_query": "What is the Value of the property?",
                "range_from": "50000",
                "range_to": "1000000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "input_query": "How much is the deposit available?",
                "range_from": "25000",
                "range_to": "500000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "03": {
                "input_query": "LTV",
                "value_01": "01",
                "value_02": "02",
                "input_type": "calculator"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q109",
            "button_text": "Show Rates"
        }
    },
    {
        "query_id": "q35",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q36"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q36"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q36"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q36"
            }
        }
    },
    {
        "query_id": "q36",
        "query": "About your credit history",
        "input_options": {
            "01": {
                "option_id": "q107_02",
                "input_query": "Do you have good credit history?",
                "input_type": "radio",
                "options": {
                    "yes": {
                        "text": "Yes",
                        "col_width": "6"
                    },
                    "no": {
                        "text": "No",
                        "col_width": "6"
                    }
                }
            },
            "02": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "2",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q37",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q37",
        "query": "What is your rental income?",
        "input_options": {
            "01": {
                "input_query": "What would be the Rental Income?",
                "range_from": "400",
                "range_to": "4000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "input_query": "What is annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q38",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q38",
        "query": "What type of mortgage you prefer ? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q39"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q39"
            }
        }
    },
    {
        "query_id": "q39",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q40"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q40"
            }
        }
    },
    {
        "query_id": "q40",
        "query": "What type of property is it ?",
        "options": {
            "flat": {
                "text": "Flat",
                "img": "flat.png",
                "col_width": "6",
                "next_id": "q41"
            },
            "house": {
                "text": "House",
                "img": "house.png",
                "col_width": "6",
                "next_id": "q41"
            }
        }
    },
    {
        "query_id": "q41",
        "query": "Is it new build?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q42"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q42"
            }
        }
    },
    {
        "query_id": "q42",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "When was property build?",
                "range_from": "1900",
                "range_to": "2020",
                "range_unit": "",
                "unit_type": "year",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q43",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q43",
        "query": "Is your property freehold or leasehold?",
        "options": {
            "freehold": {
                "text": "Freehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q44"
            },
            "leasehold": {
                "text": "Leasehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q44"
            }
        }
    },
    {
        "query_id": "q44",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many bedrooms?",
                "range_from": "1",
                "range_to": "6",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "bedrooms",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "submit",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q45",
        "query": "How soon you looking to buy? ",
        "options": {
            "0-3-months": {
                "text": "0-3 months",
                "img": "0-3.png",
                "col_width": "6",
                "next_id": "q46"
            },
            "3-6-months": {
                "text": "3-6 months",
                "img": "3-6.png",
                "col_width": "6",
                "next_id": "q46"
            }
        }
    },
    {
        "query_id": "q46",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q47"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q47"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q47"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q47"
            }
        }
    },
    {
        "query_id": "q47",
        "query": "Do you have good credit history?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q48"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q48"
            }
        }
    },
    {
        "query_id": "q48",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "2",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q49",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q49",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q50",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q50",
        "query": "What type of mortgage you prefer? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q51"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q51"
            }
        }
    },
    {
        "query_id": "q51",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "submit"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "submit"
            }
        }
    },
    {
        "query_id": "q52",
        "query": "Tell us more about you?",
        "options": {
            "first-time-buyer": {
                "text": "First time buyer",
                "img": "first-time-buyer.png",
                "col_width": "6",
                "next_id": "q76"
            },
            "home-mover": {
                "text": "Home Mover",
                "img": "moving-truck.png",
                "col_width": "6",
                "next_id": "q53"
            }
        }
    },
    {
        "query_id": "q53",
        "query": "Have you found a property yet?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q55"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q68"
            }
        }
    },
    {
        "query_id": "q54",
        "query": "Get agreement in principle",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "submit"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "submit"
            }
        }
    },
    {
        "query_id": "q55",
        "query": "Tell us more about your purchase.",
        "input_options": {
            "01": {
                "input_query": "What is the value of the property?",
                "range_from": "50000",
                "range_to": "1000000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "input_query": "How much is the deposit available?",
                "range_from": "10000",
                "range_to": "500000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "03": {
                "input_query": "LTV",
                "value_01": "01",
                "value_02": "02",
                "input_type": "calculator"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q110",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q56",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q57"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q57"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q57"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q57"
            }
        }
    },
    {
        "query_id": "q57",
        "query": "More about your mortgage needs",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "2",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            },
            "02": {
                "input_query": "What is total Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q58",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q58",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q59"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q60"
            }
        }
    },
    {
        "query_id": "q59",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay? ",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q60",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q60",
        "query": "What type of mortgage you prefer ? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q61"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q61"
            }
        }
    },
    {
        "query_id": "q61",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q62"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q62"
            }
        }
    },
    {
        "query_id": "q62",
        "query": "What type of property is it?",
        "options": {
            "flat": {
                "text": "Flat",
                "img": "flat.png",
                "col_width": "6",
                "next_id": "q63"
            },
            "house": {
                "text": "House",
                "img": "house.png",
                "col_width": "6",
                "next_id": "q63"
            }
        }
    },
    {
        "query_id": "q63",
        "query": "Is it new build?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q64"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q64"
            }
        }
    },
    {
        "query_id": "q64",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "When was property build?",
                "range_from": "1900",
                "range_to": "2020",
                "range_unit": "",
                "unit_type": "year",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q65",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q65",
        "query": "Is your property freehold or leasehold?",
        "options": {
            "freehold": {
                "text": "Freehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q67"
            },
            "leasehold": {
                "text": "Leasehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q67"
            }
        }
    },
    {
        "query_id": "q66",
        "query": "How can we help?",
        "options": {
            "remortgage": {
                "text": "Remortgage",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q3"
            },
            "purchase": {
                "text": "Purchase",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q33"
            }
        }
    },
    {
        "query_id": "q67",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many bedrooms?",
                "range_from": "1",
                "range_to": "6",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "bedrooms",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "submit",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q68",
        "query": "How soon you looking to buy? ",
        "options": {
            "0-3-months": {
                "text": "0-3 months",
                "img": "0-3.png",
                "col_width": "6",
                "next_id": "q69"
            },
            "3-6-months": {
                "text": "3-6 months",
                "img": "3-6.png",
                "col_width": "6",
                "next_id": "q69"
            }
        }
    },
    {
        "query_id": "q69",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q70"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q70"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q70"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q70"
            }
        }
    },
    {
        "query_id": "q70",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "4",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q71",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q71",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is total Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q72",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q72",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q73"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q74"
            }
        }
    },
    {
        "query_id": "q73",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay?",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q74",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q74",
        "query": "What type of mortgage you prefer ? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q75"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q75"
            }
        }
    },
    {
        "query_id": "q75",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "submit"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "submit"
            }
        }
    },
    {
        "query_id": "q76",
        "query": "Have you found a property yet?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q77"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q89"
            }
        }
    },
    {
        "query_id": "q77",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the value of the property?",
                "range_from": "100000",
                "range_to": "1000000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "02": {
                "input_query": "How much is the deposit available?",
                "range_from": "25000",
                "range_to": "500000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            },
            "03": {
                "input_query": "LTV",
                "value_01": "01",
                "value_02": "02",
                "input_type": "calculator"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q111",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q78",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q79"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q79"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q79"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q79"
            }
        }
    },
    {
        "query_id": "q79",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "4",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            },
            "02": {
                "input_query": "What is total Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q80",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q80",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q81"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q82"
            }
        }
    },
    {
        "query_id": "q81",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay?",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q82",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q82",
        "query": "What type of mortgage you prefer? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q83"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q83"
            }
        }
    },
    {
        "query_id": "q83",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q84"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q84"
            }
        }
    },
    {
        "query_id": "q84",
        "query": "What type of property is it?",
        "options": {
            "flat": {
                "text": "Flat",
                "img": "flat.png",
                "col_width": "6",
                "next_id": "q85"
            },
            "house": {
                "text": "House",
                "img": "house.png",
                "col_width": "6",
                "next_id": "q85"
            }
        }
    },
    {
        "query_id": "q85",
        "query": "Is it new build?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q86"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q86"
            }
        }
    },
    {
        "query_id": "q86",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "When was property build?",
                "range_from": "1900",
                "range_to": "2020",
                "range_unit": "",
                "unit_type": "year",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q87",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q87",
        "query": "Is your property freehold or leasehold?",
        "options": {
            "freehold": {
                "text": "Freehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q88"
            },
            "leasehold": {
                "text": "Leasehold",
                "img": "/",
                "col_width": "6",
                "next_id": "q88"
            }
        }
    },
    {
        "query_id": "q88",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many bedrooms?",
                "range_from": "1",
                "range_to": "6",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "bedrooms",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "Let’s find you most suitable mortgage.",
            "next_id": "submit",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q89",
        "query": "How soon you looking to buy? ",
        "options": {
            "0-3-months": {
                "text": "0-3 months",
                "img": "0-3.png",
                "col_width": "6",
                "next_id": "q98"
            },
            "3-6-months": {
                "text": "3-6 months",
                "img": "3-6.png",
                "col_width": "6",
                "next_id": "q98"
            }
        }
    },
    {
        "query_id": "q90",
        "query": "Select Employment Status",
        "options": {
            "employed": {
                "text": "Employed",
                "img": "employed.svg",
                "col_width": "6",
                "next_id": "q91"
            },
            "self-employed": {
                "text": "Self – Employed",
                "img": "self-employed.svg",
                "col_width": "6",
                "next_id": "q91"
            },
            "contractor": {
                "text": "Contractor",
                "img": "contractor.svg",
                "col_width": "6",
                "next_id": "q91"
            },
            "other": {
                "text": "Other",
                "img": "other.svg",
                "col_width": "6",
                "next_id": "q91"
            }
        }
    },
    {
        "query_id": "q91",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "How many people applying for mortgage?",
                "range_from": "1",
                "range_to": "4",
                "range_unit": "",
                "range_text_before": "",
                "range_text_after": "people",
                "input_type": "range"
            },
            "02": {
                "input_query": "What is total Basic annual income?",
                "range_from": "10000",
                "range_to": "170000",
                "range_unit": "£",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q92",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q92",
        "query": "Do you have credit card or loan to pay?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q93"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q94"
            }
        }
    },
    {
        "query_id": "q93",
        "query": "",
        "input_options": {
            "01": {
                "input_query": "What is the total outstanding to pay?",
                "range_from": "50",
                "range_to": "25000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q94",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q94",
        "query": "What type of mortgage you prefer? ",
        "options": {
            "repayment": {
                "text": "Repayment",
                "img": "repayment.png",
                "col_width": "6",
                "next_id": "q95"
            },
            "interest-only": {
                "text": "Interest only",
                "img": "interest-only.svg",
                "col_width": "6",
                "next_id": "q95"
            }
        }
    },
    {
        "query_id": "q95",
        "query": "Do you like to add fees to mortgage?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.svg",
                "col_width": "6",
                "next_id": "q54"
            },
            "no": {
                "text": "No",
                "img": "no.svg",
                "col_width": "6",
                "next_id": "q54"
            }
        }
    },
    {
        "query_id": "q96",
        "query": "How can we help?",
        "options": {
            "home-mover": {
                "text": "Home Mover",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q53"
            }
        }
    },
    {
        "query_id": "q97",
        "query": "How can we help?",
        "options": {
            "first-time-buyer": {
                "text": "First time buyer",
                "img": "mariannaFS.jpg",
                "col_width": "6",
                "next_id": "q76"
            }
        }
    },
    {
        "query_id": "q98",
        "query": "",
        "input_options": {
            "02": {
                "input_query": "How much is the deposit available?",
                "range_from": "25000",
                "range_to": "500000",
                "range_unit": "£",
                "unit_type": "number",
                "range_text_before": "£",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q90",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q99",
        "query": "Do you have additional income?",
        "options": {
            "child-benefit": {
                "text": "Child benefit",
                "img": "/",
                "col_width": "4",
                "next_id": "q100"
            },
            "second-job": {
                "text": "Second job",
                "img": "/",
                "col_width": "4",
                "next_id": "q100"
            },
            "other": {
                "text": "Other",
                "img": "/",
                "col_width": "4",
                "next_id": "q100"
            }
        }
    },
    {
        "query_id": "q100",
        "query": "Do you have financial dependent child or adult?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "/",
                "col_width": "6",
                "next_id": "q101"
            },
            "no": {
                "text": "No",
                "img": "/",
                "col_width": "6",
                "next_id": "q102"
            }
        }
    },
    {
        "query_id": "q101",
        "query": "How many financial dependent child or adult?",
        "input_options": {
            "01": {
                "input_query": "",
                "range_from": "1",
                "range_to": "5",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q102",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q102",
        "query": "What your age at next birthday?",
        "input_options": {
            "01": {
                "input_query": "",
                "range_from": "18",
                "range_to": "65",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q9",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q103",
        "query": "Do you have additional income?",
        "options": {
            "child-benefit": {
                "text": "Child benefit",
                "img": "/",
                "col_width": "6",
                "next_id": "q104"
            },
            "second-job": {
                "text": "Second job",
                "img": "/",
                "col_width": "6",
                "next_id": "q104"
            },
            "other": {
                "text": "Other",
                "img": "/",
                "col_width": "6",
                "next_id": "q104"
            }
        }
    },
    {
        "query_id": "q104",
        "query": "Do you have financial dependent child or adult?",
        "options": {
            "yes": {
                "text": "Yes",
                "img": "yes.png",
                "col_width": "6",
                "next_id": "q106"
            },
            "no": {
                "text": "No",
                "img": "no.png",
                "col_width": "6",
                "next_id": "q105"
            }
        }
    },
    {
        "query_id": "q105",
        "query": "What your age at next birthday?",
        "input_options": {
            "01": {
                "input_query": "",
                "range_from": "18",
                "range_to": "65",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q24",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q106",
        "query": "How many financial dependent child or adult?",
        "input_options": {
            "01": {
                "input_query": "",
                "range_from": "1",
                "range_to": "5",
                "range_unit": "",
                "unit_type": "number",
                "range_text_before": "",
                "range_text_after": "",
                "input_type": "range"
            }
        },
        "next_option": {
            "text": "",
            "next_id": "q105",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q107",
        "query": "Mortgage Rates",
        "rates": {
            "input_type": "buy-to-let-remortgage",
            "next_id": "q5"
        },
        "next_option": {
            "text": "",
            "next_id": "q5",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q108",
        "query": "Mortgage Rates",
        "rates": {
            "input_type": "remortgage",
            "next_id": "q20"
        },
        "next_option": {
            "text": "",
            "next_id": "q20",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q109",
        "query": "Mortgage Rates",
        "rates": {
            "input_type": "buy-to-let-purchase",
            "next_id": "q35"
        },
        "next_option": {
            "text": "",
            "next_id": "q35",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q110",
        "query": "Mortgage Rates",
        "rates": {
            "input_type": "buy-to-let-purchase",
            "next_id": "q55"
        },
        "next_option": {
            "text": "",
            "next_id": "q55",
            "button_text": "Next"
        }
    },
    {
        "query_id": "q111",
        "query": "Mortgage Rates",
        "rates": {
            "input_type": "first-time-buyer",
            "next_id": "q78"
        },
        "next_option": {
            "text": "",
            "next_id": "q78",
            "button_text": "Next"
        }
    }
];
const leadsData = (state = initial, action)=>{
    switch(action.type){
        case 'LEADS_DATA':
                return state = action.data;
        default: 
            return state;
    }
}

export default leadsData;