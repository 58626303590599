import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AddComma } from './index';

const initialValues = {
  'annualSalary1':0, 'additionalIncome1': 0,
  'annualSalary2':0, 'additionalIncome2': 0,
  'curentYearNetProfit1':0, 'lastYearNetProfit1':0, 
  'curentYearNetProfit2':0, 'lastYearNetProfit2':0, 
  'directorSalary1':0, 'recentYearDividend1':0, 'previousYearDividend1':0, 
  'directorSalary2':0, 'recentYearDividend2':0, 'previousYearDividend2':0, 
  'dayRateIncome1':0, 
  'dayRateIncome2':0, 
}
function SelfEmployed(props){
  const [applicant, setApplicant] = useState(['Employed', 'Sole trader', 'Limited company director', 'Contractor']);  
  const [applicantActive, setApplicantActive] = useState([0, 0]);  
  const [applicantResult, setApplicantResult] = useState([0, 0]);  
  const [input, setInput] = useState(initialValues);  
  function handleDropdown(key,number){
    // console.log(key,number);
    let temp = applicantActive;
    temp[number] = key;
    setApplicantActive([...temp]);
  }
  function handleChange(e){
    let inputTemp = input;
    let value;
    // console.log(!isNaN(e.target.value),e.target.value);
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      inputTemp[e.target.name] = parseFloat(value);
      console.log(parseFloat(value))
      setInput({...inputTemp});
    }
    calculate();
  }
  function Employed(props) {
    console.log(props);
    return (
      <></>
    );
  }
  function calculate(){
    let applicantActiveTemp = applicantActive;
    applicantActiveTemp.map((item,itemKey)=>{
      let inputName1 = '',inputName2 = '',inputName3 = '',result = '',applicantResultTemp = '';
      switch(item){
        case 0:
          inputName1 = 'annualSalary'+(itemKey+1);
          inputName2 = 'additionalIncome'+(itemKey+1);
          result = (input[inputName1]+input[inputName2])*4.75;
          applicantResultTemp = applicantResult;
          if(isNaN(+result)){
            result = 0;
          }
          applicantResultTemp[itemKey] = result;
          setApplicantResult([...applicantResultTemp]);
          break;
        case 1:
          inputName1 = 'curentYearNetProfit'+(itemKey+1);
          inputName2 = 'lastYearNetProfit'+(itemKey+1);
          result = (input[inputName1])*4.75;
          applicantResultTemp = applicantResult;
          if(isNaN(+result)){
            result = 0;
          }
          applicantResultTemp[itemKey] = result;
          setApplicantResult([...applicantResultTemp]);
          break;
        case 2:
          inputName1 = 'directorSalary'+(itemKey+1);
          inputName2 = 'recentYearDividend'+(itemKey+1);
          inputName3 = 'previousYearDividend'+(itemKey+1);
          result = (input[inputName1]+input[inputName2])*4.75;
          applicantResultTemp = applicantResult;
          if(isNaN(+result)){
            result = 0;
          }
          applicantResultTemp[itemKey] = result;
          setApplicantResult([...applicantResultTemp]);
          break;
        case 3:
          inputName1 = 'dayRateIncome'+(itemKey+1);
          result = (input[inputName1])*5*48*5;
          applicantResultTemp = applicantResult;
          if(isNaN(+result)){
            result = 0;
          }
          applicantResultTemp[itemKey] = result;
          setApplicantResult([...applicantResultTemp]);
          break;
        default:
          break;
      }
    })
  }
  useEffect(() => {    
    if(props.pageId==="contractor-mortgage"){
      setApplicantActive([3,3]);
    }
  }, []);
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [applicantActive]);
  // console.log(props.pageId);
  return (
    <>
      <div className="calculator-wrapper my-31">
        <div className="range-container">
          <h4 className="text-center">Self-employed Calculator</h4>
          <div className="container-fluid">
            <div className="row">
              <div className="option-label col-md-6">
                <div className="mb-2">Applicant 1</div>
                <Dropdown className="d-block mb-3">
                  <Dropdown.Toggle as="div" variant="success" id="dropdown-applicant1">
                    {applicant[applicantActive[0]]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      applicant.map((item, itemKey)=>
                        <Dropdown.Item key={itemKey} as="div" onClick={()=>handleDropdown(itemKey,0)}>{item}</Dropdown.Item>
                      )
                    }
                  </Dropdown.Menu>
                </Dropdown>
                {
                  (applicantActive[0]==0) &&
                  <>
                    <div className="new-label-input">  
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Annual salary:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.annualSalary1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="annualSalary1"
                          value={input.annualSalary1} onChange={handleChange} />
                      </div>
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Additional income:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.additionalIncome1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="additionalIncome1"
                          value={input.additionalIncome1} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[0]==1) &&
                  <>
                    <div className="new-label-input">                      
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Current year net profit:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.curentYearNetProfit1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="curentYearNetProfit1" value={input.curentYearNetProfit1} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Last year net profit:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.lastYearNetProfit1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="lastYearNetProfit1"
                          value={input.lastYearNetProfit1} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[0]==2) &&
                  <>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Director Salary:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.directorSalary1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="directorSalary1"
                          value={input.directorSalary1} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                      <div className="option-label-text ml-2 px-1">Recent Year Dividend:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.recentYearDividend1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="recentYearDividend1"
                          value={input.recentYearDividend1} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Previous Year Dividend:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.previousYearDividend1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="previousYearDividend1"
                          value={input.previousYearDividend1} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[0]==3) &&
                  <>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Day rate income:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.dayRateIncome1)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text"name="dayRateIncome1"
                          value={input.dayRateIncome1} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="option-label col-md-6">
                <div className="mb-2">Applicant 2</div>
                <Dropdown className="d-block mb-3">
                  <Dropdown.Toggle as="div" variant="success" id="dropdown-applicant1">
                    {applicant[applicantActive[1]]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="d-block">
                    {
                      applicant.map((item, itemKey)=>
                        <Dropdown.Item key={itemKey} as="div" onClick={()=>handleDropdown(itemKey,1)}>{item}</Dropdown.Item>
                      )
                    }
                  </Dropdown.Menu>
                </Dropdown>
                {
                  (applicantActive[1]==0) &&
                  <>
                    <div className="new-label-input">  
                      <div className="option-label d-flex">
                      <div className="option-label-text ml-2 px-1">Annual salary:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.annualSalary2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="annualSalary2"
                          value={input.annualSalary2} onChange={handleChange} />
                      </div>
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Additional income:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.additionalIncome2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="additionalIncome2"
                          value={input.additionalIncome2} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[1]==1) &&
                  <>
                    <div className="new-label-input">                      
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Current year net profit:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.curentYearNetProfit2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="curentYearNetProfit2" value={input.curentYearNetProfit2} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Last year net profit:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.lastYearNetProfit2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="lastYearNetProfit2"
                          value={input.lastYearNetProfit2} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[1]==2) &&
                  <>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Director Salary:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.directorSalary2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="directorSalary2"
                          value={input.directorSalary2} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                      <div className="option-label-text ml-2 px-1">Recent Year Dividend:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.recentYearDividend2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="recentYearDividend2"
                          value={input.recentYearDividend2} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Previous Year Dividend:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.previousYearDividend2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text" name="previousYearDividend2"
                          value={input.previousYearDividend2} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
                {
                  (applicantActive[1]==3) &&
                  <>
                    <div className="new-label-input">
                      <div className="option-label d-flex">
                        <div className="option-label-text ml-2 px-1">Day rate income:</div>
                        <div className="range-value-container px-1">
                          £ <span className="range-value">{AddComma(input.dayRateIncome2)}</span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <input className="range-slider" type="text"name="dayRateIncome2"
                          value={input.dayRateIncome2} onChange={handleChange} />
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="col-md-12 pt-2">
                <div className="option-label d-flex justify-content-center">
                  Potential borrow amount:
                  <div className="range-value-container ml-2">
                    £ <span className="range-value">{AddComma(applicantResult[0]+applicantResult[1])}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SelfEmployed;
