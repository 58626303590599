const initial = {
    // "home":{
    //     "id": 2,
    //     "date": "2021-02-20T17:29:01",
    //     "date_gmt": "2021-02-20T17:29:01",
    //     "guid": {
    //         "rendered": "https://new.mariannafs.co.uk/?page_id=2"
    //     },
    //     "modified": "2021-09-29T13:25:27",
    //     "modified_gmt": "2021-09-29T13:25:27",
    //     "slug": "home",
    //     "status": "publish",
    //     "type": "page",
    //     "link": "https://api.mariannafs.co.uk/promotion/home/",
    //     "title": {
    //         "rendered": "Home"
    //     },
    //     "content": {
    //         "rendered": "\n<h2 class=\"has-text-align-center\"><strong><strong>See how easy our process is&#8230;</strong></strong></h2>\n\n\n\n<ul class=\"discussion-list max-600 mx-auto\"><li><div class=\"discussion-icon discussion-list-icon\"><span>Discussion</span></div><div><strong>Initial Discussion</strong>We are able to offer you a free initial appointment, So If you wish to contact us, Our team can guarantee a swift mortgage with access to exclusive mortgage deals from whole of market.</div></li><li><div class=\"work-icon discussion-list-icon\"><span>Work</span></div><div><strong>We Do All Hard work</strong>After We meet, All the Preparation and Research will Immediately be Completed in order to find You the best Suitable mortgage. Marianna Financial will manage all the paper work of mortgage application, and while keeping you updated every step of the way, You won’t have to worry about a thing.</div></li><li><div class=\"deal-icon discussion-list-icon\"><span>Deal</span></div><div><strong>Secure Mortgage Deal</strong>When we find the right Mortgage deal and you are happy to proceed, Our team shall Complete Mortgage Application and keep you notified until we got the Mortgage offer.</div></li></ul>\n",
    //         "protected": false
    //     },
    //     "excerpt": {
    //         "rendered": "<p>See how easy our process is&#8230; Discussion Initial DiscussionWe are able to offer you a free initial appointment, So If you wish to contact us, Our team can guarantee a swift mortgage with access to exclusive mortgage deals from whole of market. Work We Do All Hard workAfter We meet, All the Preparation and Research [&hellip;]</p>\n",
    //         "protected": false
    //     },
    //     "author": 1,
    //     "featured_media": 0,
    //     "parent": 0,
    //     "menu_order": 0,
    //     "comment_status": "closed",
    //     "ping_status": "open",
    //     "template": "",
    //     "meta": [],
    //     "categories": [],
    //     "tags": [],
    //     "acf": {
    //         "meta_title": "Marianna Financial Services Free Mortgage Broker",
    //         "meta_description": "Free Mortgage Advisors for your mortgages we are expert in mortgage advise if you are first time buyer home mover looking or  remortgage to better rate get in touch to see how our expert friendly advisor can help and best part is we are free mortgage broker.",
    //         "calculators": [
    //             "monthly_repayments",
    //             "borrow"
    //         ],
    //         "content_section_2": "<h2 class=\"mb-4 pb-4\" style=\"text-align: center;\"><strong>Why you should work with us</strong></h2>\n<div class=\"container-fluid\">\n<div class=\"row justify-content-between text-center\">\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We  are  free </h5>\n<p>That is right! We are a fee free mortgage broker. Our services won’t cost you a penny.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">Swift &amp; exclusive deals</h5>\n<p>We have access to exclusive mortgage deals from the whole market and can quickly find you mortgage solutions tailored to your circumstances.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We do all the hard work</h5>\n<p>Our team will do all the hard work for you and make your mortgage application as hassle-free as possible. We will keep you updated every step of the way.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We are independent</h5>\n<p>Mortgage brokers have access to a wide range of specialist mortgages and can help you to get the best mortgage deal for your particular circumstances. </p>\n</div>\n</div>\n</div>\n<h3 class=\"mt-4\" style=\"text-align: center;\"><a class=\"new-outline-mfs-btn\" href=\"/contact\">Get your free initial advice now</a></h3>\n",
    //         "content_section_3": "<p style=\"text-align: center; color: #fff;\"><strong>If you ready to make an enquiry our experience mortgage broker can help to secure best mortgage for you</strong>.</p>\n",
    //         "banner_content": "<h1 class=\"has-text-align-center\"><strong> Free Expert Mortgage Advice</strong></h1>\n<h2 class=\"h4 has-text-align-center\">Trusted Mortgage Advisors </p>\n<p>  we can save you time &#038; money without charging you penny </h2>\n",
    //         "lead_form": [
    //             {
    //                 "value": "q1",
    //                 "label": "Purchase/Remortgage"
    //             }
    //         ],
    //         "content_section_4": "",
    //         "content_section_5": "",
    //         "section_background": "https://api.mariannafs.co.uk/promotion/wp-content/uploads/2021/03/services-bg.jpg",
    //         "template": {
    //             "value": "home",
    //             "label": "Home"
    //         },
    //         "sub_banner_content": "<p>#<strong> 100% Free Mortgage Advice # We Search Whole Of Market # We Do The Paperwork</strong></p>\n<p><strong> MORTGAGES </strong></p>\n<p><strong>Choose a type of mortgage will remove it along with bad credit box. All the box will be reduce to go in one line.</strong></p>\n",
    //         "content_section_1": "",
    //         "content_section_6": "",
    //         "content_section_7": "<h4 class=\"mb-5\" style=\"text-align: center;\"><strong>Three easy steps to get your mortgage sorted…</strong></h4>\n<ul class=\"easy-process-list home-page-process home-page-process1 max-900 m-auto\">\n<li>\n<div class=\"discussion-icon discussion-list-icon\">1</div>\n<div><strong>   INITIAL DISCUSSION</strong><br />\nWe do not charge broker fees, so if you wish to contact us, our team can guarantee a swift mortgage with access to exclusive mortgage deals from the whole market.</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\">2</div>\n<div><strong>    WE DO ALL THE HARD WORK</strong><br />\nAfter our initial discussion, we will immediately do all the preparation and research in order to find you the best mortgage for your circumstances.</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\">3</div>\n<div><strong>    SECURE YOUR MORTGAGE DEAL</strong><br />\nWhen we find the right mortgage deal and you are happy to proceed, our team will complete the necessary paperwork and keep you updated until the completion: best of all, it is free.</div>\n</li>\n</ul>\n",
    //         "footer_disclaimer": "<div style=\"text-align: center;\"><strong>As a mortgage is secured against your home, it may be repossessed if you do not keep up the mortgage repayments</strong></div>\n",
    //         "meta_keywords": "Best free Mortgage Advice,  free independent mortgage advisor, free expert mortgage advice, Mortgage broker Brentford, free Mortgage broker harrow. free home mover mortgage, whole of market mortgage, free Mortgage Broker, best mortgage deals, exclusive mortgage deals, free mortgage deal, free Contractor Mortgage, Free Mortgage Broker, Free Mortgage advisor near me, Online mortgage broker, Self employed Mortgage,  no free Remortgage, No fee Buy to let."
    //     },
    //     "_links": {
    //         "self": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/pages/2"
    //             }
    //         ],
    //         "collection": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/pages"
    //             }
    //         ],
    //         "about": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/types/page"
    //             }
    //         ],
    //         "author": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/users/1"
    //             }
    //         ],
    //         "replies": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/comments?post=2"
    //             }
    //         ],
    //         "version-history": [
    //             {
    //                 "count": 162,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/pages/2/revisions"
    //             }
    //         ],
    //         "predecessor-version": [
    //             {
    //                 "id": 966,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/pages/2/revisions/966"
    //             }
    //         ],
    //         "wp:attachment": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/media?parent=2"
    //             }
    //         ],
    //         "wp:term": [
    //             {
    //                 "taxonomy": "category",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/categories?post=2"
    //             },
    //             {
    //                 "taxonomy": "post_tag",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/tags?post=2"
    //             }
    //         ],
    //         "curies": [
    //             {
    //                 "name": "wp",
    //                 "href": "https://api.w.org/{rel}",
    //                 "templated": true
    //             }
    //         ]
    //     },
    //     "_embedded": {
    //         "author": [
    //             {
    //                 "id": 1,
    //                 "name": "Marianna",
    //                 "url": "https://new.mariannafs.co.uk",
    //                 "description": "",
    //                 "link": "https://api.mariannafs.co.uk/promotion/author/marianna/",
    //                 "slug": "marianna",
    //                 "avatar_urls": {
    //                     "24": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=24&d=mm&r=g",
    //                     "48": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=48&d=mm&r=g",
    //                     "96": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=96&d=mm&r=g"
    //                 },
    //                 "acf": [],
    //                 "_links": {
    //                     "self": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/users/1"
    //                         }
    //                     ],
    //                     "collection": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/promotion/wp-json/wp/v2/users"
    //                         }
    //                     ]
    //                 }
    //             }
    //         ]
    //     }
    // },
    // "first-time-buyer": {
    //     "id": 58,
    //     "date": "2021-02-22T09:01:41",
    //     "date_gmt": "2021-02-22T09:01:41",
    //     "guid": {
    //         "rendered": "https://new.mariannafs.co.uk/?page_id=58"
    //     },
    //     "modified": "2021-08-15T05:53:18",
    //     "modified_gmt": "2021-08-15T05:53:18",
    //     "slug": "first-time-buyer",
    //     "status": "publish",
    //     "type": "page",
    //     "link": "https://api.mariannafs.co.uk/first-time-buyer/",
    //     "title": {
    //         "rendered": "First Time Buyer"
    //     },
    //     "content": {
    //         "rendered": "\n<h3 class=\"has-text-align-center\"><strong>First Time Buyers Guide</strong></h3>\n\n\n\n<p class=\"has-text-align-center\"><strong>Buying a first house is one of the important purchases which can be Confusing but we can make it Exciting.</strong></p>\n\n\n\n<p>Getting a mortgage for the first time can be very confusing such as, Which deal to go for? Or, How much can I borrow? Not only this but with current house prices and low deposits will restrict your mortgage options. There are many things to consider and you may not know where to start.</p>\n",
    //         "protected": false
    //     },
    //     "excerpt": {
    //         "rendered": "<p>First Time Buyers Guide Buying a first house is one of the important purchases which can be Confusing but we can make it Exciting. Getting a mortgage for the first time can be very confusing such as, Which deal to go for? Or, How much can I borrow? Not only this but with current house [&hellip;]</p>\n",
    //         "protected": false
    //     },
    //     "author": 1,
    //     "featured_media": 0,
    //     "parent": 0,
    //     "menu_order": 0,
    //     "comment_status": "closed",
    //     "ping_status": "closed",
    //     "template": "",
    //     "meta": [],
    //     "categories": [
    //         7,
    //         11
    //     ],
    //     "tags": [],
    //     "acf": {
    //         "meta_title": "First Time Buyer - Marianna Financial Services",
    //         "meta_description": "FIRST TIME BUYER For Expert Mortgage Advise Arrange a Free Initial Appointment live first time buyer deals Make an enquiry today for more information on the mortgage products below About first time buyer Getting a mortgage for the first time can be very confusing like...",
    //         "calculators": [
    //             "monthly_repayments",
    //             "borrow"
    //         ],
    //         "content_section_2": "<h4 class=\"mb-5\" style=\"text-align: center;\"><strong>See how easy our process is&#8230;</strong></h4>\n<ul class=\"easy-process-list max-6001 m-auto\">\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>1</span></div>\n<div><strong>INITIAL DISCUSSION</strong><br />\nWe are able to offer you a free initial appointment. So, if you wish to contact us, our team can guarantee a swift mortgage with access to exclusive mortgage deals from the whole market.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>2</span></div>\n<div><strong>WE DO ALL THE HARD WORK</strong><br />\nAfter we meet, we will immediately do all the preparation and research in order to find you the best mortgage for your circumstances.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>3</span></div>\n<div><strong>SECURE YOUR MORTGAGE DEAL</strong><br />\nWhen we find the right mortgage deal and you are happy to proceed, our team will complete a mortgage application, send necessary paperwork to lenders and keep you notified until we get the mortgage offer for you.\n</div>\n</li>\n</ul>\n",
    //         "content_section_3": "<h4 style=\"text-align: center;\"><strong>Who is a first-time buyer?</strong></h4>\n<p>A first-time buyer is someone who has never owned property either in the UK or abroad. Commercial property does not count. For example, if you own or owned a shop or a restaurant with no living space upstairs, you can be considered a first-time buyer. However, if you are looking to buy a property with someone who has a property or has previously owned it, then you would not classify as a first-time buyer.</p>\n",
    //         "lead_form": [
    //             {
    //                 "value": "q97",
    //                 "label": "First time buyer"
    //             }
    //         ],
    //         "banner_content": "<h2><strong>First-time buyer mortgage made simple</strong></h2>\n<h4><strong>A trusted mortgage provider for first-time buyers to get you onto the property ladder</strong></h4>\n<div class=\"\">Answer a few questions about your first-time buyer mortgage and get your initial free expert mortgage<br />\nadvice</div>\n",
    //         "content_section_4": "<h4 style=\"text-align: center;\"><strong>Do I need a first-time buyer mortgage broker?</strong></h4>\n<p>When you buy your first house or a flat, you need to consider several things like which mortgage deal is right for me or how long it will take to secure a mortgage.</p>\n<p>Your mortgage broker can explore all available options based on your circumstances. Banks, on the other hand, show you mortgages available through them. Independent brokers will advise on the most suitable deal that will work just for you and guide you through the entire process from start to finish.</p>\n<p>Mortgage advisors have a crucial role in communicating with a lender and estate agent. Once your mortgage deal is finalised, they prepare an agreement in principle that many estate agents may require to consider your offer. Finally, when you confirm your offer on a property, your mortgage advisor will submit a full mortgage application to the lender.</p>\n",
    //         "content_section_5": "<h4 style=\"text-align: center;\"><strong>What are the steps of a first-time buying process?</strong></h4>\n<p>After an initial discussion, your first-time buying mortgage advisor will provide you with<br />\ninformation on how much you can borrow.</p>\n<ul>\n<li>The next step is to find the right property in the area you wish to live.</li>\n<li>When you find the right property, you can make an offer, and if it is accepted, your mortgage<br />\nadvisor can apply for your mortgage application to the lender.</li>\n<li>The lender will then arrange a survey, and if everything meets their requirements, they will issue a<br />\nmortgage offer.</li>\n<li>In the meantime, your solicitor will commence legal work to exchange contracts and conduct<br />\nnecessary searches and surveys.</li>\n<li>Once the legal work is completed, the lender will release funds, and your purchase will be<br />\ncompleted.</li>\n</ul>\n<p>Please, note this is not a full description of the buying process, and it can include other important legal documents that your solicitor may need to prepare. Please, consult with your solicitor for more details.</p>\n<p>If you do not have a solicitor who can carry out conveyancing work for you, we can recommend a solicitor from our partner network.</p>\n",
    //         "section_background": false,
    //         "template": {
    //             "value": "services",
    //             "label": "Services"
    //         },
    //         "sub_banner_content": "<h5><strong>Buying your first house is one of the most important purchases of your life and can be equally exciting and daunting. Our experienced first-time buyer mortgage advisor can guide you through the process and find a suitable mortgage deal.</strong></h5>\n",
    //         "content_section_1": "<h2 class=\"mb-5 pb-5\" style=\"text-align: center;\"><strong>Why you should work with us</strong></h2>\n<div class=\"container-fluid\">\n<div class=\"row justify-content-between text-center\">\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">No mortgage, no fee</h5>\n<p>Our fees are only payable if we sort out your mortgage. Till then, it won’t cost you a penny.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">Swift &amp; exclusive deals</h5>\n<p>We have access to exclusive mortgage deals from the whole market and can quickly find you mortgage solutions tailored to your circumstances.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We do all the hard work</h5>\n<p>Our team will do all the hard work for you and make your mortgage application as hassle-free as possible. We will keep you updated every step of the way &#8211; you won’t have to worry about a thing.</p>\n</div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We are independent brokers</h5>\n<p>Mortgage brokers have access to a wide range of specialist mortgages and can help you to get the best mortgage deal for your particular circumstances. Banks, on the other hand, show you only mortgages available through them, and you don&#8217;t know if you fit their lending criteria.</p>\n</div>\n</div>\n</div>\n<h3 class=\"mt-5\" style=\"text-align: center;\"><a class=\"new-outline-mfs-btn\" href=\"/new/contact\">Get your free initial advice now</a></h3>\n",
    //         "content_section_6": "<h4 style=\"text-align: center;\"><strong>What are the important costs I need to consider?</strong></h4>\n<ul>\n<li>A broker fee is a fee payable to arrange your mortgage. The typical broker fee ranges from £295 to £695, depending on the case.</li>\n<li>Solicitor fees are fees payable to your solicitors for conveyancing, legal work required for your house purchase. A typical fee ranges from £1100 to £2000.</li>\n<li>A survey fee is a fee payable for the valuation of a property. Not every lender charges the survey fee, and it depends on the chosen mortgage deal. It ranges from £250-£500, depending on the property price and the type of survey.</li>\n<li>A lender’s fee, also known as a product fee, is charged by a lender. It is mentioned in a mortgage quotation. Depending on a chosen mortgage deal, it varies from £00 to £1495. It can be paid on the mortgage application or added to the loan.</li>\n<li>Stamp duty is not payable for the first-time buyer purchase if a purchase price is under £300,000. Your solicitor can confirm if you need to pay stamp duty when they send you a quotation.</li>\n</ul>\n",
    //         "content_section_7": "<h5 style=\"text-align: center;\">Ready to make an enquiry? Our experienced mortgage broker can help to secure the best mortgage for you.</h5>\n",
    //         "footer_disclaimer": "<div style=\"text-align: center;\"><strong>As a mortgage is secured against your home, it may be repossessed if you do not keep up the mortgage repayments</strong></div>\n",
    //         "faq_05": "<h4 style=\"text-align: center;\"><strong>How long does the whole process take?</strong></h4>\n<p>Once your offer on the property is accepted, it can take 3-8 weeks from submitting a full application to a mortgage offer.</p>\n<p>From the mortgage offer to completion, it can take 8-12 weeks, depending on the amount of work required (eg. searches, surveys, etc).</p>\n<p>A leasehold property usually takes longer to complete than a freehold property.</p>\n",
    //         "faq_06": "<h4 style=\"text-align: center;\"><strong>What are different home buying options?</strong></h4>\n<ul>\n<li>Help to Buy (HTB) is a scheme run by the government to help people buy their first property. Most commonly in HTB, you contribute 5% of the deposit, and 20% or 40% (in London) deposit is provided by the government. A mortgage is raised on 75% of the property price.</li>\n<li>The government deposit is interest-free for the first five years, and then interest is charged.</li>\n<li>In Shared Ownership, you can purchase a share of the property from 25% to 75%. You will pay rent on the remaining percentage of the share that you do not own. For example, if you purchase a 25% share, you need to pay rent for the 75% remaining share. You can always buy the remaining shares in the future, known as staircasing.</li>\n</ul>\n",
    //         "faq_07": ""
    //     },
    //     "_links": {
    //         "self": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/58"
    //             }
    //         ],
    //         "collection": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages"
    //             }
    //         ],
    //         "about": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/types/page"
    //             }
    //         ],
    //         "author": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //             }
    //         ],
    //         "replies": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/comments?post=58"
    //             }
    //         ],
    //         "version-history": [
    //             {
    //                 "count": 53,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/58/revisions"
    //             }
    //         ],
    //         "predecessor-version": [
    //             {
    //                 "id": 841,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/58/revisions/841"
    //             }
    //         ],
    //         "wp:attachment": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/media?parent=58"
    //             }
    //         ],
    //         "wp:term": [
    //             {
    //                 "taxonomy": "category",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories?post=58"
    //             },
    //             {
    //                 "taxonomy": "post_tag",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/tags?post=58"
    //             }
    //         ],
    //         "curies": [
    //             {
    //                 "name": "wp",
    //                 "href": "https://api.w.org/{rel}",
    //                 "templated": true
    //             }
    //         ]
    //     },
    //     "_embedded": {
    //         "author": [
    //             {
    //                 "id": 1,
    //                 "name": "Marianna",
    //                 "url": "https://new.mariannafs.co.uk",
    //                 "description": "",
    //                 "link": "https://api.mariannafs.co.uk/author/marianna/",
    //                 "slug": "marianna",
    //                 "avatar_urls": {
    //                     "24": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=24&d=mm&r=g",
    //                     "48": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=48&d=mm&r=g",
    //                     "96": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=96&d=mm&r=g"
    //                 },
    //                 "acf": [],
    //                 "_links": {
    //                     "self": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //                         }
    //                     ],
    //                     "collection": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users"
    //                         }
    //                     ]
    //                 }
    //             }
    //         ],
    //         "wp:term": [
    //             [
    //                 {
    //                     "id": 7,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/",
    //                     "name": "leads-form",
    //                     "slug": "leads-form",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=7"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=7"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 },
    //                 {
    //                     "id": 11,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/purchase/",
    //                     "name": "purchase",
    //                     "slug": "purchase",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/11"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "up": [
    //                             {
    //                                 "embeddable": true,
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=11"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=11"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 }
    //             ],
    //             []
    //         ]
    //     }
    // },
    // "remortgaging": {
    //     "id": 60,
    //     "date": "2021-02-22T09:02:22",
    //     "date_gmt": "2021-02-22T09:02:22",
    //     "guid": {
    //         "rendered": "https://new.mariannafs.co.uk/?page_id=60"
    //     },
    //     "modified": "2021-08-15T05:54:50",
    //     "modified_gmt": "2021-08-15T05:54:50",
    //     "slug": "remortgaging",
    //     "status": "publish",
    //     "type": "page",
    //     "link": "https://api.mariannafs.co.uk/remortgaging/",
    //     "title": {
    //         "rendered": "Remortgaging"
    //     },
    //     "content": {
    //         "rendered": "\n<h3 class=\"has-text-align-center\"><strong><strong>About Remortgage</strong></strong></h3>\n\n\n\n<p>Re-mortgaging means switching your mortgage to another deal – often with another lender or same lender.</p>\n\n\n\n<p>Remortgaging is often seen as an effective tool to potentially save money on your monthly mortgage payments, especially if you are on wrong or higher interest rate. Some factors to be considered for Re-Mortgaging by lender:</p>\n\n\n\n<ul><li><strong>Purpose of the loan</strong></li><li><strong>Value of security</strong></li><li><strong>Applicant’s circumstances</strong></li><li><strong>Other underwriting factors</strong></li></ul>\n",
    //         "protected": false
    //     },
    //     "excerpt": {
    //         "rendered": "<p>About Remortgage Re-mortgaging means switching your mortgage to another deal – often with another lender or same lender. Remortgaging is often seen as an effective tool to potentially save money on your monthly mortgage payments, especially if you are on wrong or higher interest rate. Some factors to be considered for Re-Mortgaging by lender: Purpose [&hellip;]</p>\n",
    //         "protected": false
    //     },
    //     "author": 1,
    //     "featured_media": 0,
    //     "parent": 0,
    //     "menu_order": 0,
    //     "comment_status": "closed",
    //     "ping_status": "closed",
    //     "template": "",
    //     "meta": [],
    //     "categories": [
    //         7,
    //         9
    //     ],
    //     "tags": [],
    //     "acf": {
    //         "meta_title": "",
    //         "meta_description": "",
    //         "calculators": [
    //             "monthly_repayments",
    //             "borrow"
    //         ],
    //         "lead_form": [
    //             {
    //                 "value": "q2",
    //                 "label": "Remortgaging(Buy to let/Residential)"
    //             }
    //         ],
    //         "content_section_2": "<h4 class=\"mb-5\" style=\"text-align: center;\"><strong>See how easy our process is&#8230;</strong></h4>\n<ul class=\"easy-process-list max-6001 m-auto\">\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>1</span></div>\n<div><strong>INITIAL DISCUSSION</strong><br />\nWe are able to offer you a free initial appointment. So, if you wish to contact us, our team can guarantee a swift mortgage with access to exclusive mortgage deals from the whole market.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>2</span></div>\n<div><strong>WE DO ALL THE HARD WORK</strong><br />\nAfter we meet, we will immediately do all the preparation and research in order to find you the best mortgage for your circumstances.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>3</span></div>\n<div><strong>SECURE YOUR MORTGAGE DEAL</strong><br />\nWhen we find the right mortgage deal and you are happy to proceed, our team will complete a mortgage application, send necessary paperwork to lenders and keep you notified until we get the mortgage offer for you.\n</div>\n</li>\n</ul>\n",
    //         "content_section_3": "<h4 style=\"text-align: center;\"><strong>What is remortgaging?</strong></h4>\n<p>Remortgaging means switching your mortgage to another deal –with another or the same lender &#8211; in order to save money on your mortgage.</p>\n<p>Some factors to be considered for remortgaging by a lender are Purpose of the loan, Value of property, Applicant’s circumstance, Other underwriting factors</p>\n",
    //         "banner_content": "<h1><strong>Remortgage deals</strong></h2>\n<h2 class=\"h4\"><strong>Cut your mortgage interest rates. Save money on monthly mortgage payment.</strong></h4>\n<div class=\"\">Take only 60 seconds to answer several questions below and get your free initial expert mortgage advice.</div>\n",
    //         "content_section_4": "<h4 style=\"text-align: center;\"><strong>Why should you remortgage your current property?</strong></h4>\n<p>Remortgaging is often seen as an effective tool to potentially save money on your monthly mortgage payments, especially if you are on a wrong or higher interest rate. When your fixed term is over, your monthly mortgage payment will go up. You could save on your monthly mortgage payment by remortgaging to a lower rate.</p>\n<p>It is always a good idea to shop around as you may find a much better deal with a different lender than your current lender can offer you. By remortgaging, you may be able to borrow additional funds from your lender, for example, to pay for home improvements, use them as a deposit for a new purchase or simply pay off your other commitments.</p>\n",
    //         "content_section_5": "<h4 style=\"text-align: center;\"><strong>When should you remortgage?</strong></h4>\n<p>Most of the fixed term deals are for 2, 3 and 5 years. You can start the process 3 months before the fixed term comes to an end, which allows plenty of time to sort out all the paperwork.</p>\n",
    //         "section_background": false,
    //         "template": {
    //             "value": "services",
    //             "label": "Services"
    //         },
    //         "content_section_1": "<h2 class=\"mb-5 pb-5\" style=\"text-align: center;\"><strong>Why you should work with us</strong></h2>\n<div class=\"container-fluid\">\n<div class=\"row justify-content-between text-center\">\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">No mortgage, no fee</h5>\n<p>            Our fees are only payable if we sort out your mortgage. Till then, it won’t cost you a penny.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">Swift &#038; exclusive deals</h5>\n<p>            We have access to exclusive mortgage deals from the whole market and can quickly find you mortgage solutions tailored to your circumstances.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We do all the hard work</h5>\n<p>            Our team will do all the hard work for you and make your mortgage application as hassle-free as possible. We will keep you updated every step of the way &#8211; you won’t have to worry about a thing.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\"> We are independent</h5>\n<p>            Mortgage brokers have access to a wide range of specialist mortgages and can help you to get the best mortgage deal for your particular circumstances. Banks, on the other hand, show you only mortgages available through them, and you don&#8217;t know if you fit their lending criteria.\n        </p></div>\n</p></div>\n</div>\n<h3 class=\"mt-5\" style=\"text-align: center;\"><a class=\"new-outline-mfs-btn\" href=\"/new/contact\">Get your free initial advice now</a></h3>\n",
    //         "content_section_6": "<h4 style=\"text-align: center;\"><strong>How long does it take to remortgage?</strong></h4>\n<p>Remortgaging process is faster as compared to the original mortgage. Remortgaging could take 4-8 weeks to complete in most cases. An expert mortgage broker can make the process much faster and smoother.</p>\n",
    //         "content_section_7": "<h5 style=\"text-align: center;\">Ready to make an enquiry? Our experienced mortgage broker can help to secure the best mortgage for you.</h5>\n",
    //         "footer_disclaimer": "<div style=\"text-align: center;\"><strong>As a mortgage is secured against your home, it may be repossessed if you do not keep up the mortgage repayments</strong></div>\n",
    //         "sub_banner_content": "<h5><strong>Your monthly mortgage repayments can be a substantial amount of your monthly income, so you should check for better deals from other lenders that will allow you to make lower repayments on better terms. Our expert mortgage brokers will find you the best remortgage deal for your special needs and seamlessly move you to your new mortgage deal.</strong></h5>\n",
    //         "faq_05": "<h4 style=\"text-align: center;\"><strong>Can I remortgage for home improvement?</strong></h4>\n<p>If you thinking of home improvement like new kitchen or extension or any other home improvement remortgage can help you to raised funds. Home improvement can add value to your property means it is investment for long term. But do remember more borrowing means higher monthly repayment. There is always limit how much you can raise for home improvement and other factor like income may have impact on borrowing. It is always good idea to get professional advice from expert mortgage advisor.</p>\n",
    //         "faq_06": "<h4 style=\"text-align: center;\"><strong>Can you remortgage to buy other property?</strong></h4>\n<p>If you want to get another property as buy to let or second home you can remortgage to raised deposit. Buying additional property could add to your asset but borrowing more could increased your monthly payment. Again, borrowing extra depend many other factors like value of the property and income. It is always recommended to see mortgage broker for advice.</p>\n",
    //         "faq_07": "",
    //         "meta_keywords": "Remortgage for home improvement, remortgage expert, best rate remortgage, fixed rate remortgage, remortgage mortgage advisor, remortgage broker, remortgage broker near me, free remortgage."
    //     },
    //     "_links": {
    //         "self": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/60"
    //             }
    //         ],
    //         "collection": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages"
    //             }
    //         ],
    //         "about": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/types/page"
    //             }
    //         ],
    //         "author": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //             }
    //         ],
    //         "replies": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/comments?post=60"
    //             }
    //         ],
    //         "version-history": [
    //             {
    //                 "count": 55,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/60/revisions"
    //             }
    //         ],
    //         "predecessor-version": [
    //             {
    //                 "id": 844,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/60/revisions/844"
    //             }
    //         ],
    //         "wp:attachment": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/media?parent=60"
    //             }
    //         ],
    //         "wp:term": [
    //             {
    //                 "taxonomy": "category",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories?post=60"
    //             },
    //             {
    //                 "taxonomy": "post_tag",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/tags?post=60"
    //             }
    //         ],
    //         "curies": [
    //             {
    //                 "name": "wp",
    //                 "href": "https://api.w.org/{rel}",
    //                 "templated": true
    //             }
    //         ]
    //     },
    //     "_embedded": {
    //         "author": [
    //             {
    //                 "id": 1,
    //                 "name": "Marianna",
    //                 "url": "https://new.mariannafs.co.uk",
    //                 "description": "",
    //                 "link": "https://api.mariannafs.co.uk/author/marianna/",
    //                 "slug": "marianna",
    //                 "avatar_urls": {
    //                     "24": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=24&d=mm&r=g",
    //                     "48": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=48&d=mm&r=g",
    //                     "96": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=96&d=mm&r=g"
    //                 },
    //                 "acf": [],
    //                 "_links": {
    //                     "self": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //                         }
    //                     ],
    //                     "collection": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users"
    //                         }
    //                     ]
    //                 }
    //             }
    //         ],
    //         "wp:term": [
    //             [
    //                 {
    //                     "id": 7,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/",
    //                     "name": "leads-form",
    //                     "slug": "leads-form",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=7"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=7"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 },
    //                 {
    //                     "id": 9,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/remortgaging/",
    //                     "name": "remortgaging",
    //                     "slug": "remortgaging",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/9"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "up": [
    //                             {
    //                                 "embeddable": true,
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=9"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=9"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 }
    //             ],
    //             []
    //         ]
    //     }
    // },
    // "buy-to-let": {
    //     "id": 62,
    //     "date": "2021-02-22T09:02:37",
    //     "date_gmt": "2021-02-22T09:02:37",
    //     "guid": {
    //         "rendered": "https://new.mariannafs.co.uk/?page_id=62"
    //     },
    //     "modified": "2021-08-15T05:48:21",
    //     "modified_gmt": "2021-08-15T05:48:21",
    //     "slug": "buy-to-let",
    //     "status": "publish",
    //     "type": "page",
    //     "link": "https://api.mariannafs.co.uk/buy-to-let/",
    //     "title": {
    //         "rendered": "Buy to let"
    //     },
    //     "content": {
    //         "rendered": "\n<h3 class=\"has-text-align-center\">About Buy to Let Mortgage</h3>\n\n\n\n<p>Buy to let is generally regarded as a specialist market. There are various lenders and advises available in this range.</p>\n\n\n\n<p>Buy to let is frequently used by landlords for additional property investments without any intention to live in the purchased property. It is a means to raise additional rental income. You require higher deposit and higher stamp duty on buy to let.</p>\n\n\n\n<p>Buy to let mortgages have change in recent times like rent and loan ratio but the range of specialist buy to let mortgages you have access to have been designed to help first time landlords, self-employed and contractor workers.</p>\n",
    //         "protected": false
    //     },
    //     "excerpt": {
    //         "rendered": "<p>About Buy to Let Mortgage Buy to let is generally regarded as a specialist market. There are various lenders and advises available in this range. Buy to let is frequently used by landlords for additional property investments without any intention to live in the purchased property. It is a means to raise additional rental income. [&hellip;]</p>\n",
    //         "protected": false
    //     },
    //     "author": 1,
    //     "featured_media": 0,
    //     "parent": 0,
    //     "menu_order": 0,
    //     "comment_status": "closed",
    //     "ping_status": "closed",
    //     "template": "",
    //     "meta": [],
    //     "categories": [
    //         10,
    //         7
    //     ],
    //     "tags": [],
    //     "acf": {
    //         "meta_title": "",
    //         "meta_description": "",
    //         "calculators": [
    //             "buy_to_let_calculator",
    //             "buy_to_let_borrow_calculator"
    //         ],
    //         "lead_form": [
    //             {
    //                 "value": "q66",
    //                 "label": "Buy to let"
    //             }
    //         ],
    //         "content_section_2": "<h4 class=\"mb-5\" style=\"text-align: center;\"><strong>See how easy our process is&#8230;</strong></h4>\n<ul class=\"easy-process-list max-6001 m-auto\">\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>1</span></div>\n<div><strong>INITIAL DISCUSSION</strong><br />\nWe are able to offer you a free initial appointment. So, if you wish to contact us, our team can guarantee a swift mortgage with access to exclusive mortgage deals from the whole market.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>2</span></div>\n<div><strong>WE DO ALL THE HARD WORK</strong><br />\nAfter we meet, we will immediately do all the preparation and research in order to find you the best mortgage for your circumstances.\n</div>\n</li>\n<li>\n<div class=\"discussion-icon discussion-list-icon\"><span>3</span></div>\n<div><strong>SECURE YOUR MORTGAGE DEAL</strong><br />\nWhen we find the right mortgage deal and you are happy to proceed, our team will complete a mortgage application, send necessary paperwork to lenders and keep you notified until we get the mortgage offer for you.\n</div>\n</li>\n</ul>\n",
    //         "content_section_3": "<h4 style=\"text-align: center;\"><strong>What does buy-to-let mean?</strong></h4>\n<p>Buy-to-let means pretty much what it sounds like: buy-to-let property is a property that you purchase or remortgage to rent out without any intention to live there. If you are thinking about renting your current home, then you will need to convert your current mortgage to a buy-to-let mortgage.</p>\n<p>Buy-to-let is generally regarded as a specialist market with various lenders and advisers available. Landlords frequently use buy-to-let for property investments as a popular way to earn additional rental income. You require a higher deposit and higher stamp duty on a buy-to-let property.</p>\n<p>Buy-to-let mortgages have seen several changes take place, including the increase in rent and loan ratio.</p>\n",
    //         "banner_content": "<h1 class=\"has-text-align-center\"><strong>Easier, hassle-free buy-to-let mortgage</strong></h2>\n<h2 class=\"h4 has-text-align-center\">Save your time and money with our swift, exclusive buy-to-let mortgage deals.</h4>\n<div class=\"\">Take only 60 seconds to answer several questions below and get your free initial expert mortgage advice.</div>\n",
    //         "content_section_4": "<h4 style=\"text-align: center;\"><strong>Do I need a broker for a buy-to-let mortgage?</strong></h4>\n<p>Mortgage brokers have access to a wide range of specialist buy-to-let mortgages available to first-time landlords, self-employed and contractor workers.</p>\n<p>A good mortgage advisor can help you to get the best mortgage deal for your particular circumstances, leading you through the entire process of your mortgage application from start to finish. Banks, on the other hand, show you only mortgages available through them, and you don&#8217;t know if you fit their lending criteria.</p>\n",
    //         "content_section_5": "<h4 style=\"text-align: center;\"><strong>What is a buy-to-let remortgage?</strong></h4>\n<p>Buy-to-let remortgaging is often seen as an effective tool to potentially save money on your monthly mortgage payments, especially if you are on a wrong or higher interest rate. When your fixed term is over, your monthly mortgage payment will go up.</p>\n<p>You could save on your monthly mortgage payment by remortgaging to a lower rate. It is always a good idea to shop around as you may find a much better deal with a different lender than your current lender can offer you.</p>\n",
    //         "section_background": false,
    //         "template": {
    //             "value": "services",
    //             "label": "Services"
    //         },
    //         "content_section_1": "<h2 class=\"mb-5 pb-5\" style=\"text-align: center;\"><strong>Why you should work with us</strong></h2>\n<div class=\"container-fluid\">\n<div class=\"row justify-content-between text-center\">\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">No mortgage, no fee</h5>\n<p>            Our fees are only payable if we sort out your mortgage. Till then, it won’t cost you a penny.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">Swift &#038; exclusive deals</h5>\n<p>            We have access to exclusive mortgage deals from the whole market and can quickly find you mortgage solutions tailored to your circumstances.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\">We do all the hard work</h5>\n<p>            Our team will do all the hard work for you and make your mortgage application as hassle-free as possible. We will keep you updated every step of the way &#8211; you won’t have to worry about a thing.\n        </p></div>\n<div class=\"col-md-3 pb-3 pb-md-0\">\n<h5 class=\"mb-5\" style=\"font-weight: 600;\"> We are independent</h5>\n<p>            Mortgage brokers have access to a wide range of specialist mortgages and can help you to get the best mortgage deal for your particular circumstances. Banks, on the other hand, show you only mortgages available through them, and you don&#8217;t know if you fit their lending criteria.\n        </p></div>\n</p></div>\n</div>\n<h3 class=\"mt-5\" style=\"text-align: center;\"><a class=\"new-outline-mfs-btn\" href=\"/new/contact\">Get your free initial advice now</a></h3>\n",
    //         "content_section_6": "<h4 style=\"text-align: center;\"><strong>What things do I need to consider when getting a buy-to-let mortgage?</strong></h4>\n<p>The minimum deposit for a buy-to-let mortgage is higher: most buy-to-let mortgages require a deposit of around 25%. Some lenders may offer 20% deposit mortgages but at a higher interest rate.</p>\n<p>You may have to keep your buy-to-let property empty for some time before you find a new tenant.</p>\n<p>A number of new tax changes have affected buy-to-let profit margins.</p>\n",
    //         "content_section_7": "<h5 style=\"text-align: center;\">Ready to make an enquiry? Our experienced mortgage broker can help to secure the best mortgage for you.</h5>\n",
    //         "footer_disclaimer": "<div style=\"text-align: center;\"><strong>As a mortgage is secured against your home, it may be repossessed if you do not keep up the mortgage repayments</strong></div>\n",
    //         "sub_banner_content": "<h5><strong>Finding the right buy-to-let mortgage can be a daunting task. Our trusted mortgage broker can quickly find you the most exclusive deals, provide prompt, worry-free services and do all the hard work for you.</strong></h5>\n",
    //         "meta_keywords": "Buy to let remortgage, Buy to let mortgage advisor, Buy to let fixed rate, Best BTL mortgage deals, Let to buy mortgage, Buy to let home improvement.",
    //         "faq_05": ""
    //     },
    //     "_links": {
    //         "self": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/62"
    //             }
    //         ],
    //         "collection": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages"
    //             }
    //         ],
    //         "about": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/types/page"
    //             }
    //         ],
    //         "author": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //             }
    //         ],
    //         "replies": [
    //             {
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/comments?post=62"
    //             }
    //         ],
    //         "version-history": [
    //             {
    //                 "count": 96,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/62/revisions"
    //             }
    //         ],
    //         "predecessor-version": [
    //             {
    //                 "id": 837,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages/62/revisions/837"
    //             }
    //         ],
    //         "wp:attachment": [
    //             {
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/media?parent=62"
    //             }
    //         ],
    //         "wp:term": [
    //             {
    //                 "taxonomy": "category",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories?post=62"
    //             },
    //             {
    //                 "taxonomy": "post_tag",
    //                 "embeddable": true,
    //                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/tags?post=62"
    //             }
    //         ],
    //         "curies": [
    //             {
    //                 "name": "wp",
    //                 "href": "https://api.w.org/{rel}",
    //                 "templated": true
    //             }
    //         ]
    //     },
    //     "_embedded": {
    //         "author": [
    //             {
    //                 "id": 1,
    //                 "name": "Marianna",
    //                 "url": "https://new.mariannafs.co.uk",
    //                 "description": "",
    //                 "link": "https://api.mariannafs.co.uk/author/marianna/",
    //                 "slug": "marianna",
    //                 "avatar_urls": {
    //                     "24": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=24&d=mm&r=g",
    //                     "48": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=48&d=mm&r=g",
    //                     "96": "https://secure.gravatar.com/avatar/ee58ecd0a3875d1d1f9a5e08b179020a?s=96&d=mm&r=g"
    //                 },
    //                 "acf": [],
    //                 "_links": {
    //                     "self": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users/1"
    //                         }
    //                     ],
    //                     "collection": [
    //                         {
    //                             "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/users"
    //                         }
    //                     ]
    //                 }
    //             }
    //         ],
    //         "wp:term": [
    //             [
    //                 {
    //                     "id": 10,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/buy-to-let/",
    //                     "name": "buy to let",
    //                     "slug": "buy-to-let",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/10"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "up": [
    //                             {
    //                                 "embeddable": true,
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=10"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=10"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 },
    //                 {
    //                     "id": 7,
    //                     "link": "https://api.mariannafs.co.uk/category/leads-form/",
    //                     "name": "leads-form",
    //                     "slug": "leads-form",
    //                     "taxonomy": "category",
    //                     "acf": [],
    //                     "_links": {
    //                         "self": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories/7"
    //                             }
    //                         ],
    //                         "collection": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/categories"
    //                             }
    //                         ],
    //                         "about": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/taxonomies/category"
    //                             }
    //                         ],
    //                         "wp:post_type": [
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/posts?categories=7"
    //                             },
    //                             {
    //                                 "href": "https://api.mariannafs.co.uk/wp-json/wp/v2/pages?categories=7"
    //                             }
    //                         ],
    //                         "curies": [
    //                             {
    //                                 "name": "wp",
    //                                 "href": "https://api.w.org/{rel}",
    //                                 "templated": true
    //                             }
    //                         ]
    //                     }
    //                 }
    //             ],
    //             []
    //         ]
    //     }
    // }
};
const pageData = (state = initial, action)=>{
    switch(action.type){
        case 'PAGE_DATA':
                return state = action.data;
        default: 
            return state;
    }
}

export default pageData;