const initial = {
    "siteFooter": {
        "footerLogo": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/03/logo-mariannafs-white.png"
        },
        "footerBanner": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/03/footer-banner.jpg"
        },
        "headerLogo": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/02/logo.png"
        },
        "headerLogoWebp": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/06/logo_new.webp"
        },
        "trustpilotReviewCount": 172,
        "headingBackgroundImage": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/03/logo-bg-header.png"
        },
        "email": "info@mariannafs.co.uk",
        "copyrightText": "Copyright © 2020, Marianna Financial Services. All rights reserved.",
        "address": "<p>West Link House,<br />\n981 Great West Rd,<br />\nBrentford TW8<br />\n9DN</p>\n",
        "companyDescription": "<p>Marianna Financial Services is authorised and regulated by Financial Conduct Authority under the FCA financial services register number 925226 in respect of mortgage, insurance and consumer mediation activities only.</p>\n<p>Company registration number 12485005. Register in England and Wales. Register office : WEST LINK HOUSE,981 GREAT WEST ROAD, BRENTFORD, TW8 9DN</p>\n<p>The information contained within this website is subject to the UK regulatory regime and is therefore primarily targeted at consumers based within the UK.</p>\n",
        "contact": "02080902043",
        "whatsapp": 4402080902043,
        "facebook": "https://www.facebook.com/mariannafinancialservices",
        "instagram": "https://www.instagram.com/mariannafinancialservices/",
        "freeText1": "We Charge No Broker Fees",
        "freeText2": "We Search Whole Of Market",
        "freeText3": "We Do The Hard Work"
    },
    "trustpilotExtras": {
        "linkTitle": "What our customers are saying about us",
        "trustpilotLogo": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2022/02/trustpilot-new.png"
        },
        "trustpilotLogo02": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/03/trustpilot.png"
        },
        "trustpilotStars": {
            "sourceUrl": "https://mariannafs.co.uk/promotion/wp-content/uploads/2021/03/trust-pilot.png"
        },
        "trustpilotReviewCount": 150,
        "trustpilotUrl": "https://www.trustpilot.com/review/mariannafs.co.uk",
        "reviewToShowInSlider": 8
    }
};
const footerData = (state = initial, action)=>{
    switch(action.type){
        case 'FOOTER_DATA':
                return state = action.data;
        default: 
            return state;
    }
}

export default footerData;