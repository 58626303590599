import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function DealsMenu(props){
  let servicesMenu = useSelector((state) =>state.servicesMenu);
  // console.log(servicesMenu);
  let content = servicesMenu['menuItems']['nodes']?.slice(0, 4);
  let iconClass = '';
  let apiFolder = '';
  let faqText = '';
  if(process.env.REACT_APP_API_FOLDER){
    apiFolder = process.env.REACT_APP_API_FOLDER;
  }
  let activeFaq = props.currentFaq;
  let rateURL = props.rateURL || 'rates';
  return (
    <div className="faqs-links-container pt-4">
      <div className="row justify-content-center">
        {
          content &&
          content.map(function(menu_item, i) {
            iconClass = menu_item['path'].replace(apiFolder,"").replace(/\//g, "");
            faqText = menu_item['label'].replace(' mortgage',"");
            if(activeFaq===iconClass){
              return null;
            }
            return <div className="col-md-3" key={i}>
                <Link className={`faq-link btn btn-primary rounded-pill d-block`} to={`/${iconClass}/${rateURL}`}>
                  <div className="faq-btn-text">{faqText} Deals</div>                    
                </Link>
              </div>;
          })
        }      
      </div>
    </div>      
  );
}
export default DealsMenu;
