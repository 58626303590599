const initial = [
    {
        "trustpilotReviews": {
            "ratingUrl": null,
            "ratings": "5",
            "reviewDate": "Jan 10, 2024",
            "reviewDescription": "Excellent advice for First time mortgage buyers. A very friendly and professional person who can be trusted. Milton was very quick, accurate and adaptable to changes. I did not have to worry at all but received an excellent outcome. Many thanks!!!",
            "reviewTitle": "Excellent advice",
            "reviewerImageUrl": null,
            "reviewerName": "LIUDMYLA PALA",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": null,
            "ratings": "5",
            "reviewDate": "Jan 9, 2024",
            "reviewDescription": "We use MariannaFS  recently for our remortgage and we are very impress with the service.   Best remortgage broker around. excellent  mortgage advice with out any broker fees.",
            "reviewTitle": "Best remortgage broker ",
            "reviewerImageUrl": null,
            "reviewerName": "Bryan Pereira",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": null,
            "ratings": "0.5",
            "reviewDate": "Dec 12, 2023",
            "reviewDescription": "SOMETIMES I FEEL LIKE HE CARES MORE ABOUT MY MORTGAGE BEING COMPLETED MORE THAN I DO WHICH IS THE TYPE OF SERVICE I WISH I COULD GET FROM CONVEYANCERS AND ESTATE AGENTS!\r\n\r\nGREAT ADVICE AND VERY KNOWLEDGABLE!",
            "reviewTitle": "EXCELLENT SERVICE!",
            "reviewerImageUrl": null,
            "reviewerName": "vincent",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/Yw2BKi",
            "ratings": "5",
            "reviewDate": "Dec 5, 2023",
            "reviewDescription": "Best mortgage advisors around. WE used Mariannafs for our house purchase. They have Great knowledge about mortgage and quick to respond. Would surely recommend them for an excellent mortgage advice.",
            "reviewTitle": "Best mortgage advisors around",
            "reviewerImageUrl": null,
            "reviewerName": "Rohan Almeida",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": null,
            "ratings": "0.5",
            "reviewDate": "Nov 24, 2023",
            "reviewDescription": "this Mariannna FS is  an excellent mortgage advisor as mine was done very quickly and efficiently  and the rate were also very attractive , Mr Nelson Dighorker who handled my case was very courteous and was very good in professional advice ! thanks",
            "reviewTitle": "excellent mortgage advisor",
            "reviewerImageUrl": null,
            "reviewerName": "Vinay Mani",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/reviews/652741a9a91ad0a9c8d8958f",
            "ratings": "5",
            "reviewDate": "Oct 12, 2023",
            "reviewDescription": "Thanks for great service you provide every time I reach out to you. Your company's exceptional service has created a great experience. The commitment to delivering quality and innovation consistently exceeds expectations. Keep up the good work :-)",
            "reviewTitle": "New Purchase Mortgage service",
            "reviewerImageUrl": null,
            "reviewerName": "Sreedhar Muppiri",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19674232",
            "ratings": "5",
            "reviewDate": "Oct 10, 2023",
            "reviewDescription": "Very good service. \r\nAdvised the right thing to do. \r\nReally helpful advise.",
            "reviewTitle": "Very good service.  Advised the right thing to do.  Really helpful advise.",
            "reviewerImageUrl": null,
            "reviewerName": "Dan A",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/xDjxQ2",
            "ratings": "5",
            "reviewDate": "Sep 29, 2023",
            "reviewDescription": "Thank you for your help Milton",
            "reviewTitle": "Thank you for your help Milton",
            "reviewerImageUrl": null,
            "reviewerName": "Tarquin Purdie",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19518282",
            "ratings": "5",
            "reviewDate": "Sep 26, 2023",
            "reviewDescription": "They made our dream comes true .",
            "reviewTitle": "They made our dream comes true .",
            "reviewerImageUrl": null,
            "reviewerName": "Yash &",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19304866",
            "ratings": "5",
            "reviewDate": "Sep 19, 2023",
            "reviewDescription": "I am extremely lucky and privileged to have Milton as my mortgage advisor as he is exceptionally empathetic, understanding and caring as well as being hardworking as he works tirelessly to help me throughout this entire process and made transaction simpler for me. I would never go to any other mortgage advisor other than Milton as he is truly the best and I have recommended his service to all my friends and family as well. Thank you Milton for everything that you have done for me. You are truly one of the greatest gifts that I have received in my life. Thank you so much Milton.",
            "reviewTitle": "I am extremely lucky and privileged to have Milton as my mortgage advisor",
            "reviewerImageUrl": null,
            "reviewerName": "Dinahar",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19469080",
            "ratings": "5",
            "reviewDate": "Sep 19, 2023",
            "reviewDescription": "Excellent mortgage broker. very helpful with our first purchase.",
            "reviewTitle": "Excellent mortgage broker. very helpful with our first purchase.",
            "reviewerImageUrl": null,
            "reviewerName": "Flavia",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/RxyjdC",
            "ratings": "5",
            "reviewDate": "Sep 13, 2023",
            "reviewDescription": "Very informative and helpful services. Thanks to Milton for your help and advices. Especially with current bank rates hike, you found me a great offer.",
            "reviewTitle": "Very informative and helpful services.",
            "reviewerImageUrl": null,
            "reviewerName": "amit k",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/nGe9kX",
            "ratings": "5",
            "reviewDate": "Sep 13, 2023",
            "reviewDescription": "Milton was very helpful and tried to find the best deal for us. Great service overall.",
            "reviewTitle": "Milton was very helpful and tried to find the best deal for us. Great service overall.",
            "reviewerImageUrl": null,
            "reviewerName": "Γιώργος Κοντός",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19409931",
            "ratings": "0.5",
            "reviewDate": "Sep 12, 2023",
            "reviewDescription": "VIQAS EXCELLENT EXPERIENCE, ALWAYS AVAILABLE TO ANSWER QUESTIONS, VERY AFFORDABLE. MILTON MADE IT A VERY HASSLE FREE EXPERIENCE TO GET MY MORTGAGE UNLIKE MY PREVIOUS MORTGAGE BROKERS WHO CHARGED MUCH MORE!",
            "reviewTitle": "VIQAS EXCELLENT EXPERIENCE, ALWAYS AVAILABLE TO ANSWER QUESTIONS",
            "reviewerImageUrl": null,
            "reviewerName": "You A",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19310822",
            "ratings": "5",
            "reviewDate": "Sep 5, 2023",
            "reviewDescription": "Excellent Mortgage broker . We use them for our remortgage . We dealt with Nelson who is been very helpful. Thank you .",
            "reviewTitle": "Excellent Mortgage broker . We use them for our remortgage . ",
            "reviewerImageUrl": null,
            "reviewerName": "Anonymous",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19324260",
            "ratings": "5",
            "reviewDate": "Sep 5, 2023",
            "reviewDescription": "Excellent service from the beginning to the end of the mortgage and insurance for first time buyer.",
            "reviewTitle": "Excellent service from the beginning to the end of the mortgage and insurance for first time buyer.",
            "reviewerImageUrl": null,
            "reviewerName": "Sheldon",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19167972",
            "ratings": "5",
            "reviewDate": "Sep 2, 2023",
            "reviewDescription": "Helpful and knowledgeable.\r\n",
            "reviewTitle": "Helpful and knowledgeable.",
            "reviewerImageUrl": null,
            "reviewerName": "Charles",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19304866",
            "ratings": "5",
            "reviewDate": "Sep 2, 2023",
            "reviewDescription": "I am extremely lucky and privileged to have Milton as my mortgage advisor as he is exceptionally empathetic, understanding and caring as well as being hardworking as he works tirelessly to help me throughout this entire process and made transaction simpler for me. I would never go to any other mortgage advisor other than Milton as he is truly the best and I have recommended his service to all my friends and family as well. Thank you Milton for everything that you have done for me. You are truly one of the greatest gifts that I have received in my life. Thank you so much Milton.",
            "reviewTitle": "I am extremely lucky and privileged to have Milton as my mortgage advisor as he is exceptionally empathetic",
            "reviewerImageUrl": null,
            "reviewerName": "Dinahar",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/review/mariannafs.co.uk",
            "ratings": "5",
            "reviewDate": "Aug 25, 2023",
            "reviewDescription": "Milton is one of the best advisers around. He understood our financial situation and advised a lender accordingly.\r\n\r\nWe struggled to find a suitable lender with another adviser wasted two months and almost lost our dream home.\r\n\r\nMilton managed to find a suitable lender and we received our mortgage offer in 10 working days which was amazing.\r\n\r\nMilton is excellent with his communication and keeps updating on every step and also chases the banks and solicitors on a timely basis.\r\n\r\nI highly recommend Milton for his advice and support and sincerely thank him for making our dream of owning a house come true after we had lost all hopes and got rejected by two banks with the previous advisor/broker.\r\n",
            "reviewTitle": "Fantastic service.",
            "reviewerImageUrl": null,
            "reviewerName": "Firoz Jan",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19059164",
            "ratings": "5",
            "reviewDate": "Aug 15, 2023",
            "reviewDescription": "Very helpful and friendly with every client.",
            "reviewTitle": "Very helpful and friendly with every client.",
            "reviewerImageUrl": null,
            "reviewerName": "Hom K",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18915452",
            "ratings": "5",
            "reviewDate": "Aug 11, 2023",
            "reviewDescription": "Excellent service! Milton was very helpful throughout the whole process and made the transaction smooth. I'd recommend this service to everyone.\r\n",
            "reviewTitle": "Excellent service! Milton was very helpful throughout the whole process and made the transaction smooth. ",
            "reviewerImageUrl": null,
            "reviewerName": "Alex",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18999284",
            "ratings": "5",
            "reviewDate": "Aug 10, 2023",
            "reviewDescription": "Milton was very helpful, understanding and caring. Worked tirelessly to help meet our needs and was always available to answer any questions or concerns.Excellent !\r\n",
            "reviewTitle": "Milton was very helpful, understanding and caring.",
            "reviewerImageUrl": null,
            "reviewerName": "Mariya",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19167972",
            "ratings": "5",
            "reviewDate": "Aug 10, 2023",
            "reviewDescription": "Helpful and knowledgeable.",
            "reviewTitle": "Helpful and knowledgeable.",
            "reviewerImageUrl": null,
            "reviewerName": "Charles",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/19059164",
            "ratings": "5",
            "reviewDate": "Jul 29, 2023",
            "reviewDescription": "Very helpful and friendly with every client.",
            "reviewTitle": "Very helpful and friendly with every client.",
            "reviewerImageUrl": null,
            "reviewerName": "Hom K",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18999284",
            "ratings": "5",
            "reviewDate": "Jul 26, 2023",
            "reviewDescription": "Milton was very  helpful, understanding and caring. Worked tirelessly to help meet our needs and was always available to answer any questions or concerns.Excellent !",
            "reviewTitle": "Milton was very  helpful, understanding and caring.",
            "reviewerImageUrl": null,
            "reviewerName": "Mariya",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18762401",
            "ratings": "5",
            "reviewDate": "Jul 17, 2023",
            "reviewDescription": "We had a very good experience with Marianna FS. We have been using them for last 6 years for our purchase and remortgage.Best mortgage broker in Hounslow.",
            "reviewTitle": "We had a very good experience with Marianna FS.",
            "reviewerImageUrl": null,
            "reviewerName": "Victoria",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18800268",
            "ratings": "5",
            "reviewDate": "Jul 17, 2023",
            "reviewDescription": "Nelson provided a very personalised service taking into consideration our requirements and limitations. Would recommend for all Residential and BTL mortgages.\r\n",
            "reviewTitle": "Nelson provided a very personalised service taking into consideration our requirements and limitations. ",
            "reviewerImageUrl": null,
            "reviewerName": "Shibu",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.reviews.io/company-review/store/mariannafs.co.uk/18630839",
            "ratings": "5",
            "reviewDate": "Jun 13, 2023",
            "reviewDescription": "We cannot speak highly enough of Milton and the way he guided us through the whole mortgage process from start to finish. He has provided professional advice whilst maintaining a friendly and approachable style in everything he does. He has gone above and beyond to make himself available at times that were convenient to us. We wouldn’t ever consider going elsewhere for advice. Really prompt with replies and a great service overall. I just want to take this opportunity to say thank you for the help with securing us a mortage offer.",
            "reviewTitle": "We cannot speak highly enough of Milton and the way he guided us through the whole mortgage process from start to finish. ",
            "reviewerImageUrl": null,
            "reviewerName": "Vissi",
            "reviewScreenshot": null,
            "reviewSite": "reviewsio"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": null,
            "ratings": "5",
            "reviewDate": "Mar 15, 2023",
            "reviewDescription": "MR.Milton was outstanding.He helped me  whole process .Thank you\r\nI would really recommend him.",
            "reviewTitle": "MR.Milton was outstanding.",
            "reviewerImageUrl": null,
            "reviewerName": "Roshan Dsouza",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/wShPzz",
            "ratings": "5",
            "reviewDate": "Oct 12, 2022",
            "reviewDescription": "Met Milton, He was easy to approach. Helped us understand all the complex process that was involved and the costs that we would incur in the remortgage. Helped us choose the best option that would be beneficial to us. Was always available to all our questions. Happy to deal with all our future business with Marianna FS and would any day recommend to my family and friends.",
            "reviewTitle": "Met Milton, He was easy to approach.",
            "reviewerImageUrl": null,
            "reviewerName": "Sreedhar Muppiri",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/SqN3mw",
            "ratings": "5",
            "reviewDate": "Oct 7, 2022",
            "reviewDescription": "Very reliable service. Milton is very friendly and does work with very high professionalism. The best in the area to use the services of Mortgages, Re-Mortgages, etc.\r\nMilton answered all my doubts and advise me on the best available solutions. I am using the services a second time as I had a very good experience initially. Well done, Milton and team. Keep up the good work! 👍",
            "reviewTitle": "Very reliable service. Milton is very friendly and does work with very high professionalism. ",
            "reviewerImageUrl": null,
            "reviewerName": "Anannya P",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/t1xHsa",
            "ratings": "5",
            "reviewDate": "Oct 7, 2022",
            "reviewDescription": "Absolutely happy with Marianna FS. Milton and Sancia. They are always available for any queries and have fantastic knowledge at what they do. They are also very proactive. Would certainly recommend.",
            "reviewTitle": "Absolutely happy with Marianna FS. Milton and Sancia.",
            "reviewerImageUrl": null,
            "reviewerName": "Neil Lem",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/634089ebb67840c2ab2b4526",
            "ratings": "5",
            "reviewDate": "Oct 7, 2022",
            "reviewDescription": "Our experience with MariannaFS (Milton) has been the best. He is very professional and timely. Highly recommend him if you are looking for a mortgage.",
            "reviewTitle": "Best experience",
            "reviewerImageUrl": null,
            "reviewerName": "Rohan A",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/633aac83fc65900013615bfa",
            "ratings": "5",
            "reviewDate": "Oct 3, 2022",
            "reviewDescription": "Milton is one of the best advisers around. He understood our financial situation and advised a lender accordingly.\r\n\r\nWe struggled to find a suitable lender with another adviser wasted two months and almost lost our dream home.\r\n\r\nMilton managed to find a suitable lender and we received our mortgage offer in 10 working days which was amazing.\r\n\r\nMilton is excellent with his communication and keeps updating on every step and also chases the banks and solicitors on a timely basis.\r\n\r\nI highly recommend Milton for his advice and support and sincerely thank him for making our dream of owning a house come true after we had lost all hopes and got rejected by two banks with the previous advisor/broker.",
            "reviewTitle": "Amazing service, highly recommended!!",
            "reviewerImageUrl": null,
            "reviewerName": "Snehal Almeida",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/8k4Zpt",
            "ratings": "5",
            "reviewDate": "Oct 2, 2022",
            "reviewDescription": "I am immensely satisfied with all your support. You have given me an incredible service which I would rate 10/10 as well as me recommending it to others.\r\nThank you for all your help. I really appreciate it!",
            "reviewTitle": "I am immensely satisfied with all your support.",
            "reviewerImageUrl": null,
            "reviewerName": "Dinahar Rathnaraj",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/M6eGMq",
            "ratings": "5",
            "reviewDate": "Oct 1, 2022",
            "reviewDescription": "I am very Happy that I choose to work with Mr. Milton in Marianna FS. He was always available on calls and sorting out the queries. He understands the requirement, the available products on the market and come up with what best fits to you. He is the best and will recommend Milton over any other Mortgage advisor. Great Service!",
            "reviewTitle": "I am very Happy that I choose to work with Mr. Milton in Marianna FS.",
            "reviewerImageUrl": null,
            "reviewerName": "Manthan Shah",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/Xua1HV",
            "ratings": "5",
            "reviewDate": "Oct 1, 2022",
            "reviewDescription": "I have used Marianna FS a number of times and they were always really helpful find the best available product and solution saving me time and money. I can genuinely recommend Marianna Financial Services.",
            "reviewTitle": "I have used Marianna FS a number of times",
            "reviewerImageUrl": null,
            "reviewerName": "Mateusz Brzozowski",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/61bcbcb98145c40012dfa613",
            "ratings": "5",
            "reviewDate": "Sep 29, 2022",
            "reviewDescription": "I have used MARIANNA Services for re-mortgaging BTL Flat, where it was short lease and none of the agents were able to find lender and by Gods grace my friend has recommended to use this service and all was sorted within a quick time and also secured very good interest rates. I would recommend strongly to use this services and stay hassle free solution to all your problems. Keep up the great work !!!",
            "reviewTitle": "I have used MARIANNA Services for Re-Mortgage BTL properties.",
            "reviewerImageUrl": null,
            "reviewerName": "Sree",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/606f2cb047417900190b2e97",
            "ratings": "5",
            "reviewDate": "Aug 19, 2022",
            "reviewDescription": "I found MariannaFS very professional. They provided an insights on all possible options some of them probably we wouldn’t have thought about.\r\nWe got ample support from them throughout and had our all doubts cleared.\r\nMy honest appreciation to Milton and team for the professionalism & careful thought about our case.",
            "reviewTitle": "Efficient, insightful, timely and friendly",
            "reviewerImageUrl": null,
            "reviewerName": "Shubhra Kumar",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/62b061f6787382efe211cdec",
            "ratings": "5",
            "reviewDate": "Jun 20, 2022",
            "reviewDescription": "The best mortgage advisor, very professional and helpful. Extremely easy to work with, explained everything in detail.\r\nFive star service.",
            "reviewTitle": "The best mortgage advisor",
            "reviewerImageUrl": null,
            "reviewerName": "Javed Khan",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/62a4c0a66d54f92107130631",
            "ratings": "5",
            "reviewDate": "Jun 11, 2022",
            "reviewDescription": "We cannot thank Milton enough for his support during the process of applying for a mortgage. Milton has supported us throughout the application process, finding the right deal for us, and giving advice and guidance throughout. This has enabled the purchase of our new home to run smoothly and relieved some of the stress that moving home can create. We highly recommend the service.",
            "reviewTitle": "Excellent and Reliable",
            "reviewerImageUrl": null,
            "reviewerName": "Sara",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/627ac02b166eb7ecbf4300cb",
            "ratings": "5",
            "reviewDate": "May 11, 2022",
            "reviewDescription": "Second time with Milton after first mortgage had to be cancelled due to my change in plans. Got 2 seperate mortgages done with him, and can't fault his advise or service at any point. He was available anytime of the day, and was always very helpful in explaining things. Throughout the process he liased with the mortgage provider to get updates on the application and make sure there are no issues anywhere. Sorted out aip when needed, helped with solicitors queries and available for a friendly chat whenever needed. Highly recommended. Thanks Milton",
            "reviewTitle": "Best service with mortgages...",
            "reviewerImageUrl": null,
            "reviewerName": "Sandeep",
            "reviewScreenshot": null,
            "reviewSite": "facebook"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/6253382f2f30f500120af902",
            "ratings": "5",
            "reviewDate": "Apr 12, 2022",
            "reviewDescription": "I have done my 2 purchase mortgage and 2 re remortgages with marianna finance ,\r\nVery professional service by Milton Rodrigues, recommending the best possible deal/product that works for you\r\n\r\n",
            "reviewTitle": "I have done my 2 purchase mortgage and 2 re remortgages",
            "reviewerImageUrl": null,
            "reviewerName": "Sheetal Mohite",
            "reviewScreenshot": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2022/02/Screenshot_1.jpg"
            },
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/60660083f85d75087042f110",
            "ratings": "5",
            "reviewDate": "Apr 1, 2022",
            "reviewDescription": "Milton from Mariannafs is been very helpful in both buying us a new home and helping us to achieve our first buy to let property.He guided us from start to finish in arranging the best mortgage for both the properties very professional.Would highly recommend him.\r\nRegards\r\nJerryDcruz",
            "reviewTitle": "Milton from Mariannafs is been very",
            "reviewerImageUrl": null,
            "reviewerName": "Jerry",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/users/61e048812b562f00122097a1",
            "ratings": "5",
            "reviewDate": "Jan 13, 2022",
            "reviewDescription": "I worked with Milton. As a first time buyer, I needed more information, which he understood, explain different products, my eligibility etc. Helped me throughout the process with clear communications. And we have the end result as my Mortgage is approved. thanks for all your help Milton. Cheers!",
            "reviewTitle": "I worked with Milton",
            "reviewerImageUrl": null,
            "reviewerName": "Manthan Shah",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/57ee42970000ff000a492cd3",
            "ratings": "5",
            "reviewDate": "Dec 20, 2021",
            "reviewDescription": "Mariannafs are very professional, Milton and his team were very helpful and Co operative. He guided us throughout the process and informed us about every steps. It was a lengthy process and time consuming, being a business owner it was not easy to apply for Mortgage but because of Milton and his team’s effort we got this through.\r\nThank you everyone at Mariannafs for the Excellent job you all do.\r\nWe wish you all the very best.",
            "reviewTitle": "The Best Mortgage advisor in London",
            "reviewerImageUrl": null,
            "reviewerName": "Faheem Vanoo",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/n9AqEJ",
            "ratings": "5",
            "reviewDate": "Nov 27, 2021",
            "reviewDescription": "Very professional yet freindly. I would recommend Milton/Mariannafs to all my personal and professional contacts in need of mortgage advise.",
            "reviewTitle": "Very professional yet freindly. I would recommend Milton/Mariannafs to all my personal and professional contacts in need of mortgage advise.",
            "reviewerImageUrl": null,
            "reviewerName": "Vijay Karas",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/Qwkjmr",
            "ratings": "5",
            "reviewDate": "Oct 30, 2021",
            "reviewDescription": "Very professional service. Would recommend 👍🏻10/10",
            "reviewTitle": "Very professional service. Would recommend 👍🏻10/10",
            "reviewerImageUrl": null,
            "reviewerName": "Roshan Gurung",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/NsUgYs",
            "ratings": "5",
            "reviewDate": "Oct 28, 2021",
            "reviewDescription": "I have been using Milton's service for the past 3 years for both new Mortgage as well Re-Mortgage. He is very helpful and always make it easy to complete the procedures necessary to get the mortgage. Always go an extra mile in getting better deals. Efficient, Friendly and Professional.",
            "reviewTitle": "I have been using Milton's service for the past 3 years for both new Mortgage as well Re-Mortgage. ",
            "reviewerImageUrl": null,
            "reviewerName": "Singaravelan Gopalayer",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/t6vCXZ",
            "ratings": "5",
            "reviewDate": "Oct 22, 2021",
            "reviewDescription": "Excellent and professional Service",
            "reviewTitle": "Excellent and professional Service",
            "reviewerImageUrl": null,
            "reviewerName": "Bryan Pereira",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/61aa22982b49257fe293ad72",
            "ratings": "5",
            "reviewDate": "Sep 20, 2021",
            "reviewDescription": "We all know how difficult it is to find a good Financial Advisor. Personally, I've used a few different firms in the past but was never fully satisfied with the service that I got. When Marianna Financial Services was recommended to me I was a bit sceptical, however I have quickly realised that Milton and his brilliant team are truly outstanding. They listen patiently and try to fully understand your needs, they put forward some really good ideas and you can quickly tell that they have years of experience in the financial services sector. They went above and beyond to get me the best possible result. I couldn't recommend them enough.",
            "reviewTitle": "Truly great service",
            "reviewerImageUrl": null,
            "reviewerName": "Mateusz Brzozowski",
            "reviewScreenshot": null,
            "reviewSite": "facebook"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/60f581a8260ab40013a162c2",
            "ratings": "5",
            "reviewDate": "Jul 19, 2021",
            "reviewDescription": "Amazing Service, I have been using for a while now. Prompt Service, I had no issues complete professional, transparent and reliable services. Thank you very much and Good Luck !\r\n\r\n",
            "reviewTitle": "Amazing Service",
            "reviewerImageUrl": null,
            "reviewerName": "Anthony M",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/reviews/604ea164f85d75064c41796f",
            "ratings": "5",
            "reviewDate": "Mar 15, 2021",
            "reviewDescription": "Experienced, Knowledgeable, and Professional\r\n\r\nMr. Milton at Marianna Financial services is a very knowledgeable & experienced person who advised me on my mortgage and protection too.\r\nThroughout the process, Milton was always available to explain things in detail and professional manner\r\nThere were a few hurdles in the application with the lender and the solicitors but Milton always dealt with the issues quickly and efficiently and gave me valuable advice.\r\nI would definitely use Marianna in the future.",
            "reviewTitle": "Experienced, Knowledgeable and Professional",
            "reviewerImageUrl": null,
            "reviewerName": "Tejas Ranadive",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/reviews/6040e51af85d75064c374c29",
            "ratings": "5",
            "reviewDate": "Mar 4, 2021",
            "reviewDescription": "Marianna provided me excellent service. They have discussed various options with me and helped me to choose the best one. They are very helpful and prompt in answering any queries.",
            "reviewTitle": "Excellent service",
            "reviewerImageUrl": null,
            "reviewerName": "Agnel Rodriguez",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/reviews/6039357ff85d7509d8e589eb",
            "ratings": "5",
            "reviewDate": "Feb 27, 2021",
            "reviewDescription": "Very professional, friendly and knowledgable with realistic information. We found Mariannafs very helpful to get the right coverage and Insurance company at competitive price. We will highly recommend Mariannafs for any financial advice, including mortgage and insurances.",
            "reviewTitle": "Best professional service",
            "reviewerImageUrl": null,
            "reviewerName": "Trupti",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/review/mariannafs.co.uk?b=MTYxMzgyNTU0MzAwMHw2MDMxMDYwN2Y4NWQ3NTA5ZDhkZjA0ZGU",
            "ratings": "5",
            "reviewDate": "Feb 18, 2021",
            "reviewDescription": "Did all the research with honesty and…\r\nDid all the research with honesty and didnt ask for any monies until offer was approved by lender.\r\n\r\nSuperb. Thank you.",
            "reviewTitle": "Did all the research with honesty",
            "reviewerImageUrl": null,
            "reviewerName": "Nabeel Suleiman",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/602bc76fe375b4001a438558",
            "ratings": "5",
            "reviewDate": "Feb 16, 2021",
            "reviewDescription": "Professional and friendly mortgage services by Mariannafs (Milton)\r\nMariannafs (Milton) has been very friendly and professional throughout our remortgage process.\r\nWhat looked like a straight forward remortgage, got complicated with new building requirements which slowed the process from our end.\r\nMilton carefully worked out and advised various options to suit our requirements.\r\nDuring the entire process, we had to re-apply on occassions and finally our remortgate went through.\r\nMariannafs showed high level of commitment and patience and for all 3 applications and 6 months of commitment, Mariannafs did not leave us with a long invoice.\r\nThank you Milton for delivering high service standards.",
            "reviewTitle": "Professional and friendly mortgage services",
            "reviewerImageUrl": null,
            "reviewerName": "Manjusha",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/5ff03b81ce9f9700193fde53",
            "ratings": "5",
            "reviewDate": "Jan 2, 2021",
            "reviewDescription": "We are very very happy with the service provided by Milton. He understands the customers needs, circumstances and gives the best solution possible. His response to our queries was very quick. He was always very professional and approachable. We highly recommend him to anyone who is looking for mortgage.",
            "reviewTitle": "Highly Professional & Efficient",
            "reviewerImageUrl": null,
            "reviewerName": "Gaurang Kulkarni",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/users/5fd3a2245d74f10019fbc46a",
            "ratings": "5",
            "reviewDate": "Dec 11, 2020",
            "reviewDescription": "Great communication, always responding on time without delays. Milton is extremely knowledgeable, resourceful and helpful from getting me the best possible deal, Many Thanks\r\nWould definitely recommend him!--",
            "reviewTitle": "Brilliant service from Milton",
            "reviewerImageUrl": null,
            "reviewerName": "Joy Lopes",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://uk.trustpilot.com/reviews/5fd094ab755dc107e0bca7d5",
            "ratings": "5",
            "reviewDate": "Dec 9, 2020",
            "reviewDescription": "Very happy with the services, excellent and prompt service from Milton. Very good financial advice given not only on mortgage but also on other financial matters. Would definitely recommend to friends.",
            "reviewTitle": "Very happy with the services",
            "reviewerImageUrl": null,
            "reviewerName": "Vishal",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://www.trustpilot.com/reviews/5fbfc1f65e693f08f00b3592",
            "ratings": "5",
            "reviewDate": "Nov 26, 2020",
            "reviewDescription": "One the trusted mortgage adviser you can find in this wild world",
            "reviewTitle": "One the trusted mortgage adviser",
            "reviewerImageUrl": null,
            "reviewerName": "Haseeb Khan",
            "reviewScreenshot": null,
            "reviewSite": "trustpilot"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/fmMhCC",
            "ratings": "5",
            "reviewDate": "Nov 12, 2020",
            "reviewDescription": "I would like to strongly recommend them as they are very professional honest and provide you full information and recommendations.\r\nRecently I took couple of insurance policies and their full support for re mortgage.",
            "reviewTitle": "I would like to strongly recommend them as they are very professional honest and provide you full information and recommendations. ",
            "reviewerImageUrl": null,
            "reviewerName": "ROHIT GHAI",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/yTGvvZ",
            "ratings": "5",
            "reviewDate": "Oct 7, 2020",
            "reviewDescription": "professional and honest service!\r\n\r\nreally helpful and everything was explained step by step.\r\n\r\nDefinitely would recommend!",
            "reviewTitle": "professional and honest service!",
            "reviewerImageUrl": null,
            "reviewerName": "David Han",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    },
    {
        "trustpilotReviews": {
            "ratingUrl": "https://g.co/kgs/G8RtMT",
            "ratings": "5",
            "reviewDate": "Sep 13, 2020",
            "reviewDescription": "I would recommend Marianna Financial Services without hesitation for their outstanding service and advice around finance.  Milton is very proactive and specially knowledgeable in this field.  He is always\r\nalways at the other end with great solutions that suits your requirement.  He is very professional and provide excellent and helpful advice.  I will always count on him for good financial advice.",
            "reviewTitle": "I would recommend Marianna Financial Services without hesitation for their outstanding service and advice around finance.",
            "reviewerImageUrl": null,
            "reviewerName": "Trupti Naik",
            "reviewScreenshot": null,
            "reviewSite": "google"
        }
    }
];
const trustPilotReviews = (state = initial, action)=>{
    switch(action.type){
        case 'TRUSTPILOT_REVIEWS':
                return state = action.data;
        default: 
            return state;
    }
}

export default trustPilotReviews;