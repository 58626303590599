const initial = {};
const faqsData = (state = initial, action)=>{
    switch(action.type){
        case 'FAQS_DATA':
                return state = action.data;
        default: 
            return state;
    }
}

export default faqsData;