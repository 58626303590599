import axios from "axios";
import * as qs from 'qs';
// import { parse } from 'fast-xml-parser';
const API_URL = process.env.REACT_APP_GQL_URL;
let apiURL = process.env.REACT_APP_API_URL;
// const API_URL = 'https://api.mariannafs.co.uk/graphql';
// console.log(apiURL);

async function fetchAPI(query, { variables } = {}) {
  const headers = { 'Content-Type': 'application/json' }

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers[
      'Authorization'
    ] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`
  }

  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  // await console.log(query);
  const json = await res.json();
  if (json.errors) {
    console.table(json.errors)
    throw new Error('Failed to fetch API')
  }
  return json.data
}

export async function getPreviewPost(id, idType = 'DATABASE_ID') {
  const data = await fetchAPI(
    `
    query PreviewPost($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        databaseId
        slug
        status
      }
    }`,
    {
      variables: { id, idType },
    }
  )
  return data.post
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
    {
      posts(first: 10000) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `)
  return data?.posts
}

export async function getExtraSEOBySlug(slug) {
  const data = await fetchAPI(`
    {
      extraSEOSectionBy(slug: "${slug}") {
        title
        extraSeoSection {
          section01
          section01FaqSlug
          section02
          section02FaqSlug
          section03
          section03FaqSlug
          section04
          section04FaqSlug
          section05
          section05FaqSlug
          section06
          section06FaqSlug
          section07
          section07FaqSlug
          section08
          section08FaqSlug
          section09
          section09FaqSlug
          section010
          section010FaqSlug
        }
      }
    }
  `)
  return data;
}

export async function getPostsBySlug(slug) {
  const data = await fetchAPI(
    `query PostBySlug {
      page(id: "${slug}", idType: URI) {
        id
        title
        uri
        content
        pageExtras {
          bannerContent
          contentSection1
          contentSection2
          contentSection3
          contentSection4
          contentSection5
          contentSection6
          contentSection7
          faq05
          faq06
          faq07
          fieldGroupName
          footerDisclaimer
          subBannerContent
          template
        }
      }
    }`
  )
  return data?.posts;
}

export async function getPageBySlug(slug) {
  const data = await fetchAPI(
    `query PageBySlug {
      pageBy(uri: "${slug}") {
        id
        title
        pageExtras {
          bannerContent
          contentSection1
          contentSection2
          contentSection3
          contentSection4
          contentSection5
          contentSection6
          contentSection7
          contentSection7Faqs
          contentSection7FaqsFooter
          extraSeoSection
          extraSeoSectionName
          faq05
          faq06
          faq07
          fieldGroupName
          footerDisclaimer
          freeBanner
          readMoreStyleSection
          subBannerContent
          template
          localBranchAccordion {
            accordion1
            accordion2
            accordion3
            accordion4
          }
        }
      }
    }`
  )
  return data?.pageBy;
}

export async function testCall() {
  const data = await fetchAPI(
    `query sEOGuidePages {
      sEOGuidePages(first :51) {
        edges {
          node {
            title
            seoGuides {
              url
              image {
                mediaItemUrl
              }
              showOnHomepage
              guideCategory
              createdDate
            }
          }
        }
      }
    }`
  )
  return data.sEOGuidePages.edges;
}

export async function getCommonData() {
  const data = await fetchAPI(
    `query allData {
      footers {
        edges {
          node {
            id
            siteFooter {
              footerLogo {
                sourceUrl
              }
              footerBanner {
                sourceUrl
              }
              headerLogo {
                sourceUrl
              }
              headerLogoWebp {
                sourceUrl
              }
              trustpilotReviewCount
              headingBackgroundImage {
                sourceUrl
              }
              email
              copyrightText
              address
              companyDescription
              contact
              whatsapp
              facebook
              instagram
              freeText1
              freeText2
              freeText3
            }
          }
        }
      },
      menus {
        nodes {
          id
          databaseId
          name
          slug
          count
          menuItems(first: 1000) {
            ${ /*
            edges {
              node {
                id
                label
                parentId
                path
                title
                cssClasses
                connectedNode {
                  node {
                    uri
                  }
                }
              }
            }*/'' }
            nodes {
              id
                label
                parentId
                path
                title
                target
                cssClasses
                key: id
                parentId
                connectedNode {
                  node {
                    uri
                  }
                }
            }
          }
        }
      },
      trustPilotReviews(first :1000) {
        nodes {
          trustpilotReviews {
            ratingUrl
            ratings
            reviewDate
            reviewDescription
            reviewTitle
            reviewerImageUrl
            reviewerName
            reviewScreenshot {
              sourceUrl
            }
            reviewSite
          }
        }
      },
      post(id: "188", idType: DATABASE_ID) {
        id
        trustpilotExtras {
          linkTitle
          trustpilotLogo {
            sourceUrl
          }
          trustpilotLogo02 {
            sourceUrl
          }
          trustpilotStars {
            sourceUrl
          }
          trustpilotUrl
          reviewToShowInSlider
          google {
            googleLogo {
              sourceUrl
            }
            googleStars {
              sourceUrl
            }
          }
          facebook {
            facebookLogo {
              sourceUrl
            }
            facebookStars {
              sourceUrl
            }
          }          
          reviewio {
            reviewioLogo {
              sourceUrl
            }
            reviewioStars {
              sourceUrl
            }
          }
          reviewsCount {
            googleReviewCount
            reviewsioReviewCount
            trustpilotReviewCount
            totalReviewCount
          }
          authorPhoto {
            sourceUrl
          }
        }
        uri
        title(format: RAW)
      }
      services {
        edges {
          node {
            title
            services {
              themeColor
              themeOpacity
              serviceIcon {
                mediaItemUrl
              }
            }
            slug
          }
        }
      }
      sEOGuidePages(first :1000) {
        edges {
          node {
            title
            seoGuides {
              url
              guideDescription
              ${ /*
              image {
                mediaItemUrl
              }
              }*/'' }
              showOnHomepage
              guideCategory
              createdDate
            }
          }
        }
      }
      pages(where: {categoryId: 17}) {
        nodes {
          id
          title
          pageExtras {
            bannerImage {
              sourceUrl
            }
          }
          slug
        }
      }
      additionalBranch: pages(where: {categoryId: 19}, first: 1000) {
        nodes {
          id
          title
          pageExtras {
            bannerImage {
              sourceUrl
            }
          }
          slug
        }
      }
    }`
  )
  return data
}
export async function getFaqsData() {
  const data = await axios.all([
    axios.get(apiURL + 'wp-json/faqs/v1/get'),
  ])
  .catch(error => {
  });
  return data
}
function compareObjects(object1, object2, key) {
  const obj1 = object1[key]
  const obj2 = object2[key]                      
  if (obj1 < obj2) {
    return -1
  }
  if (obj1 > obj2) {
    return 1
  }
  return 0
} 
export function sortRatesBy(data, sortBy) {
  let responseData = data;                 
  responseData.sort((data1, data2) => {
    return compareObjects(data1, data2, sortBy)
  });
  responseData.sort(function (a, b) {
    return a[sortBy] > (b[sortBy]);
  });
  return responseData
}
export async function removeNegativeRates(data, attr) {
  let responseData = data;                 
  responseData.filter((res) => {
    console.log(parseInt(res[attr],10)<0);
    return parseInt(res.attr,10)>0;
  });
  // console.log(responseData);
  return responseData
}
export async function getSavedApiRates(data = {}) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_saved_rates/v1/get`;
  let response = [];
  const apiCall = await axios.all([
    axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
  ]).then(axios.spread((rates) => {
    let obj = JSON.parse(JSON.stringify(rates.data));
    // console.log(obj);
    if (obj['Body']['GetSchemesExResponse']) {
      let responseData = obj['Body']['GetSchemesExResponse']['objSchemes']['MBPublicSchemeSummaryEX'];
      // console.log(responseData); 
      response['data'] = responseData;
    } else {
      // console.log(obj['Body']['Fault']['faultstring']);
      response['error'] = obj['Body']['Fault']['faultstring'];
    }
  })).then(() => {
    // console.log(data);
  });
  return response
}
export async function getAllSavedApiRates(data = {}) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_saved_rates/v2/get`;
  let response = {};
  const apiCall = await axios.all([
    axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
  ]).then(axios.spread((rates) => {//
    let obj = JSON.parse(JSON.stringify(rates.data));
    Object.keys(obj).map(function(key) {
      response[key] = [];
      response[key]['data']= obj[key]['Body']['GetSchemesExResponse']['objSchemes']['MBPublicSchemeSummaryEX'];
    });    
  }));
  return response
}
export async function getSavedPluginApiRates(data = {}) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_saved_ltv_rates/v1/get`;
  let response = [];
  const apiCall = await axios.all([
    axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
  ]).then(axios.spread((rates) => {
    let obj = JSON.parse(JSON.stringify(rates.data));
    // console.log(obj);
    response = obj;
    // if (obj['Body']['GetSchemesExResponse']) {
    //   let responseData = obj['Body']['GetSchemesExResponse']['objSchemes']['MBPublicSchemeSummaryEX'];
    //   // console.log(responseData); 
    //   response['data'] = responseData;
    // } else {
    //   // console.log(obj['Body']['Fault']['faultstring']);
    //   response['error'] = obj['Body']['Fault']['faultstring'];
    // }
  })).then(() => {
    // console.log(data);
  });
  return response
}
export async function getApiRates(data = {}) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_rates/v1/get`;
  let response = [];
  try {
    await axios.all([
      axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
    ]).then(axios.spread((rates) => {
      let obj = JSON.parse(JSON.stringify(rates.data));
      // console.log(obj);
      if (obj['Body']['GetSchemesExResponse']) {
        let responseData = obj['Body']['GetSchemesExResponse']['objSchemes']['MBPublicSchemeSummaryEX'];
        // console.log(obj['Body']['GetSchemesExResponse']['objSchemes']); 
        response['data'] = responseData;
      } else {
        // console.log(obj['Body']['Fault']['faultstring']);
        response['error'] = obj['Body']['Fault']['faultstring'];
      }
    })).then(() => {
      // console.log(data);
    });
  } catch (error) {
    console.log(error);
  }
  return response
}
export async function getApiRatesDB(data = []) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_saved_rates/v2/service`;
  let response = [];
  try {
    await axios.all([
      axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
    ]).then(axios.spread((rates) => {
     response = rates.data;
    //  console.log(response)
    })).then(() => {
      // console.log(data);
    });
  } catch (error) {
    console.log(error);
  }
  return response
}

export async function getFilteredApiRates(data = {}) {
  data = qs.stringify(data);
  let api = `${apiURL}/wp-json/get_filtered_rates/v2/filter`;
  let response = [];
  try {
    await axios.all([
      axios({ method: "post", url: api, data: data, headers: { "Content-Type": 'application/x-www-form-urlencoded' } })
    ]).then(axios.spread((rates) => {
      console.log(rates)
      let obj = JSON.parse(JSON.stringify(rates.data));
      // console.log(obj);
      if (obj['Body']['GetSchemesExResponse']) {
        let responseData = obj['Body']['GetSchemesExResponse']['objSchemes']['MBPublicSchemeSummaryEX'];
        // console.log(obj['Body']['GetSchemesExResponse']['objSchemes']); 
        response['data'] = responseData;
      } else {
        // console.log(obj['Body']['Fault']['faultstring']);
        response['error'] = obj['Body']['Fault']['faultstring'];
      }
    })).then(() => {
      // console.log(data);
    });
  } catch (error) {
    console.log(error);
  }
  return response
}
export async function getAllPostsForHome(preview) {
  const data = await fetchAPI(
    `query AllPosts {
      page(id: "home", idType: URI) {
        id
        title
        uri
        content
        pageExtras {
          bannerContent
          contentSection1
          contentSection2
          contentSection3
          contentSection4
          contentSection5
          contentSection6
          contentSection7
          faq05
          faq06
          faq07
          fieldGroupName
          footerDisclaimer
          subBannerContent
          template
        }
      }
    }`,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
      },
    }
  )
  return data?.posts
}

export async function getPostAndMorePosts(slug, preview, previewData) {
  const postPreview = preview && previewData?.post
  // The slug may be the id of an unpublished post
  const isId = Number.isInteger(Number(slug))
  const isSamePost = isId
    ? Number(slug) === postPreview.id
    : slug === postPreview.slug
  const isDraft = isSamePost && postPreview?.status === 'draft'
  const isRevision = isSamePost && postPreview?.status === 'publish'
  const data = await fetchAPI(
    `
    fragment AuthorFields on User {
      name
      firstName
      lastName
      avatar {
        url
      }
    }
    fragment PostFields on Post {
      title
      excerpt
      slug
      date
      featuredImage {
        node {
          sourceUrl
        }
      }
      author {
        node {
          ...AuthorFields
        }
      }
      categories {
        edges {
          node {
            name
          }
        }
      }
      tags {
        edges {
          node {
            name
          }
        }
      }
    }
    query PostBySlug($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        ...PostFields
        content
        ${
    // Only some of the fields of a revision are considered as there are some inconsistencies
    isRevision
      ? `
        revisions(first: 1, where: { orderby: { field: MODIFIED, order: DESC } }) {
          edges {
            node {
              title
              excerpt
              content
              author {
                node {
                  ...AuthorFields
                }
              }
            }
          }
        }
        `
      : ''
    }
      }
      posts(first: 3, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            ...PostFields
          }
        }
      }
    }
  `,
    {
      variables: {
        id: isDraft ? postPreview.id : slug,
        idType: isDraft ? 'DATABASE_ID' : 'SLUG',
      },
    }
  )

  // Draft posts may not have an slug
  if (isDraft) data.post.slug = postPreview.id
  // Apply a revision (changes in a published post)
  if (isRevision && data.post.revisions) {
    const revision = data.post.revisions.edges[0]?.node

    if (revision) Object.assign(data.post, revision)
    delete data.post.revisions
  }

  // Filter out the main post
  data.posts.edges = data.posts.edges.filter(({ node }) => node.slug !== slug)
  // If there are still 3 posts, remove the last one
  if (data.posts.edges.length > 2) data.posts.edges.pop()

  return data
}
