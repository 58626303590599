import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function RemortgageCalculator(props){
  const [loan, setLoan] = useState(50000); 
  const [deposit, setDeposit] = useState(50000); 
  const [interest, setInterest] = useState(5.99); 
  const [newInterest, setNewInterest] = useState(4); 
  const [term, setTerm] = useState(25); 
  const [monthlyPay, setMonthlyPay] = useState(0); 
  const [totalPay, setTotalPay] = useState(0); 
  const [totalInterest, setTotalInterest] = useState(0); 
  const [monthlyPayNew, setMonthlyPayNew] = useState(0); 
  const [totalPayNew, setTotalPayNew] = useState(0); 
  const [totalInterestNew, setTotalInterestNew] = useState(0); 
  function handleChange(e){
    let value;
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      if(e.target.name==="loan"){
        setLoan(parseFloat(value));
      }
      if(e.target.name==="interest"){
        setInterest(parseFloat(value));
      }
      if(e.target.name==="new_interest"){
        setNewInterest(parseFloat(value));
      }
      if(e.target.name==="term"){
        setTerm(parseFloat(value));
      }
    }
  }
  function calculate(){
    let principal = parseFloat(loan);
    let interest1 = parseFloat(interest) / 100 / 12;
    let new_interest = parseFloat(newInterest) / 100 / 12;
    let payments = parseFloat(term) * 12;
    let x = Math.pow(1 + interest1, payments); 
    let monthly = (principal*x*interest1)/(x-1);
    let new_x = Math.pow(1 + new_interest, payments); 
    let new_monthly = (principal*new_x*new_interest)/(new_x-1);
    setMonthlyPay(monthly.toFixed(2));
    setTotalPay((monthly * payments).toFixed(2));
    setTotalInterest(((monthly * payments)-principal).toFixed(2));

    setMonthlyPayNew(new_monthly.toFixed(2));
    setTotalPayNew((new_monthly * payments).toFixed(2));
    setTotalInterestNew(((new_monthly * payments)-principal).toFixed(2));
  }
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [loan, interest, newInterest, term]);
  return (
    <div className="calculator-wrapper my-31">
      <div className="range-container">
        <h4 className="text-center">Remortgage Calculator</h4>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 pb-3">
              <div className="option-label d-flex">
                Mortgage Loan / Purchase Price (£):
                <div className="range-value-container ml-2">
                  <span className="range-value">{AddComma(loan)}</span>
                </div>
              </div>
              <div className="mb-2">
                <input className="range-slider" type="range" min="50000" max="1000000" name="loan"
                  value={loan} onChange={handleChange} />
              </div>
              <div className="option-label d-flex">
                Interest Rate (%):
                <div className="range-value-container ml-2">
                  <span className="range-value">{AddComma(interest)}</span>
                  </div>
                </div>
              <div className="mb-2">
                <input className="range-slider" type="range" min="1" max="8" step="0.01" name="interest"
                  value={interest} onChange={handleChange} />                      
              </div>
              <div className="option-label d-flex">
                New Interest Rate (%):
                <div className="range-value-container ml-2">
                  <span className="range-value">{AddComma(newInterest)}</span>
                  </div>
                </div>
              <div className="mb-2">
                <input className="range-slider" type="range" min="1" max="8" step="0.01" name="new_interest"
                  value={newInterest} onChange={handleChange} />                      
              </div>
              <div className="option-label d-flex">
                Term (Years):
                <div className="range-value-container ml-2">
                  <span className="range-value">{AddComma(term)}</span>
                </div>
              </div>
              <div className="mb-2">
                <input className="range-slider" type="range" min="5" max="35" name="term"
                  value={term} onChange={handleChange} />
              </div>
            </div>
            <div className="col-md-6">
              <table className="table table-striped table-bordered ">
                <thead>
                  <tr>
                    <th></th>
                    <th>{AddComma(interest)}% <div className="font-regular small">(Interest Rate)</div></th>
                    <th>{AddComma(newInterest)}% <div className="font-regular small">(Interest Rate)</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Monthly payment</td>
                    <td>£{AddComma(monthlyPay)}</td>
                    <td>£{AddComma(monthlyPayNew)}</td>
                  </tr>
                  <tr>
                    <td>Total payment</td>
                    <td>£{AddComma(totalPay)}</td>
                    <td>£{AddComma(totalPayNew)}</td>
                  </tr>
                  <tr>
                    <td>Total interest</td>
                    <td>£{AddComma(totalInterest)}</td>
                    <td>£{AddComma(totalInterestNew)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="font-medium">Payment difference</div>
          <div>Monthly payment : £{AddComma((monthlyPay - monthlyPayNew).toFixed(2))}</div>
          <div>Total payment : £{AddComma((totalPay - totalPayNew).toFixed(2))}</div>
          <div>Total interest : £{AddComma((totalInterest - totalInterestNew).toFixed(2))}</div>
        </div>
      </div>
    </div>
  );
}
export default RemortgageCalculator;