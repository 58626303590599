import { useEffect, useRef, useState } from 'react';
import GuideCTA from '../GuideCTA';
import LenderRatesTable from '../RatesApi/LenderRatesTable';

function LenderContent({ lendersData, lendersID }) {
    useEffect(() => {
    }, []);
    return(
        <>
        {
            lendersData && lendersData.map((item, i) => {
                return (
                    <div key={i}>
                        <div dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                        {
                            item.rates_tables &&
                            item.rates_tables.map((rate, count)=>{
                                return(
                                    <div key={count}>
                                        {
                                            rate?.description &&
                                            <div dangerouslySetInnerHTML={{ __html: rate?.description }}></div>
                                        }
                                        {
                                            rate?.ltv &&
                                            <LenderRatesTable ltv={rate?.ltv} lendersID={lendersID}></LenderRatesTable>
                                        }
                                    </div>
                                )
                            })
                            
                        }
                        {
                            item.cta_text &&
                            <GuideCTA cta_text={item.cta_text} />
                        }
                    </div>
                )
            })
        }
        </>
    )
}
export default LenderContent;
