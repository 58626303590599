import {combineReducers} from 'redux';
import mainMenu from './mainMenu';
import isLoaded from './isLoaded';
import leadsData from './leadsData';
import setTrustpilot from './setTrustpilot';
import trustPilotReviews from './trustPilotReviews';
import servicesMenu from './servicesMenu';
import siteMap from './siteMap';
import footerMenu from './footerMenu';
import footerData from './footerData';
import pageData from './pageData';
import faqsData from './faqsData';
import lenderLogos from './lenderLogos';
import apiRates from './apiRates';
import servicesName from './servicesName';
import pluginApiRates from './pluginApiRates';
import seoGuidePages from './seoGuidePages';

const allReducers = combineReducers({
    mainMenu,
    isLoaded,
    leadsData,
    setTrustpilot,
    trustPilotReviews,
    servicesMenu,
    siteMap,
    footerMenu,
    footerData,
    pageData,
    faqsData,
    lenderLogos,
    apiRates,
    servicesName,
    pluginApiRates,
    seoGuidePages,
})

export default allReducers;