const initial = {
    "id": "cG9zdDoxODg=",
    "trustpilotExtras": {
        "linkTitle": "What our customers are saying about us",
        "trustpilotLogo": {
            "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/5-stars.png"
        },
        "trustpilotLogo02": {
            "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/trustpilot.png"
        },
        "trustpilotStars": {
            "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/trust-pilot.png"
        },
        "trustpilotUrl": "https://www.trustpilot.com/review/mariannafs.co.uk",
        "reviewToShowInSlider": 6,
        "google": {
            "googleLogo": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/google-logo.png"
            },
            "googleStars": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/google-stars.png"
            }
        },
        "facebook": {
            "facebookLogo": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/facebook-logo.png"
            },
            "facebookStars": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/facebook-stars.png"
            }
        },
        "reviewio": {
            "reviewioLogo": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/reviewsio.png"
            },
            "reviewioStars": {
                "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2021/03/reviewsio.png"
            }
        },
        "reviewsCount": {
            "googleReviewCount": 85,
            "reviewsioReviewCount": 78,
            "trustpilotReviewCount": 181
        },
        "authorPhoto": {
            "sourceUrl": "https://api.mariannafs.co.uk/wp-content/uploads/2023/10/Milton-Pic-scaled.jpg"
        }
    },
    "uri": "/trustpilot/trustpilot-extras/",
    "title": null
};
const setTrustpilot = (state = initial, action)=>{
    switch(action.type){
        case 'TRUSTPILOT':
                return state = action.data;
        default: 
            return state;
    }
}

export default setTrustpilot;