import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { boxVariant, listVariant } from '../lib/animation';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { testCall } from '../lib/api';

function GuidesFilter(props) {
  // const [seoGuidePages, setSeoGuidePages] = useState(props.data);
  const [guidePagesFiltered, setGuidePagesFiltered] = useState([]);
  const [activeFilter, setActiveFilter] = useState(['first-time-buyer', 'property', 'mortgaging', 'remortgaging']);
  // const [activeFilter, setActiveFilter] = useState([]);
  const [activeFilterSlug, setActiveFilterSlug] = useState('all');
  let seoGuidePages = useSelector((state) => state.seoGuidePages).guidePages.edges;
  let showItems = props.showItems || 10000;
  // console.log(seoGuidePages)
  // console.log('activeFilter ',activeFilter)
  // console.log (props) 
  const location = useLocation();
  let guideFilter = location?.state?.guide;
  // console.log(guideFilter)
  useEffect(() => {
    if (props.filter) {
      filterType(props.filter)
      // setActiveFilter(props.filter);
    }
    if (guideFilter) {
      filterType(guideFilter)
      // console.log(guideFilter);
    }
  }, []);
  useEffect(() => {  
    // console.log(activeFilter, seoGuidePages)
    if (seoGuidePages) {
      if (activeFilter == ['first-time-buyer', 'property', 'mortgaging', 'remortgaging']) {
        setGuidePagesFiltered(seoGuidePages);
      } else {
        const filteredData = seoGuidePages.filter(
          // (item) => item.node.seoGuides.guideCategory.includes(activeFilter)
          (item) => {
            // console.log(activeFilter.includes(item.node.seoGuides.guideCategory))
            // console.log(activeFilter.some((element)=>item.node.seoGuides.guideCategory.includes(element)))
            // console.log(activeFilter)
            // console.log(item.node.seoGuides.guideCategory)
            return(
              // activeFilter.includes(item.node.seoGuides.guideCategory)
              (activeFilter.constructor === Array) && activeFilter.some((element)=>item.node.seoGuides.guideCategory.includes(element))
            )
          }
        );
        setGuidePagesFiltered(filteredData);
        // console.log(filteredData);
      }
    }
  }, [activeFilter, seoGuidePages]);
  const handleCategoryClick = async(category) => {
    // if (category === activeFilter) return;
    // setActiveFilter(category);
    filterType(category);
  };
  function filterType(filter){
    // console.log(filter, activeFilterSlug)
    setActiveFilterSlug(filter);
    switch(filter) {
      case 'first-time-buyer':
        setActiveFilter(['property','first-time-buyer','mortgaging']);
        break;
      case 'remortgaging':
        setActiveFilter(['property','remortgaging','mortgaging']);
        break;
      case 'buy-to-let':
        setActiveFilter(['property','remortgaging','mortgaging']);
        break;
      case 'home-mover':
        setActiveFilter(['property','remortgaging','mortgaging']);
        break;
      case 'property':
        setActiveFilter(['property']);
        break;
      case 'mortgaging':
        setActiveFilter(['mortgaging']);
        break;
      default:
        setActiveFilter(['first-time-buyer', 'property', 'mortgaging', 'remortgaging','']);
        break;
    }
  }
  function FilterButtons({ handleClick, active }) {
    // const items = ["All", "first-time-buyer", "remortgaging", "buy-to-let", "home-mover"];
    const items = {
      "all":"All Guides", 
      "property":"Property Guides", 
      "first-time-buyer":"First Time Buyer Guides", 
      "remortgaging":"Remortgage Guides", 
      "mortgaging":"Mortgage Guides", 
      // "buy-to-let":"Buy To Let", 
      // "home-mover":"Home Mover",
    };
    return (
      <div className="justify-content-between1 d-md-flex mb-2 text-uppercase">
        {Object.keys(items).map((item, i) => {
          // console.log(item, activeFilter)
          return(
          <button key={i}
            className={activeFilterSlug === item ? "mfs-blue-btn mfs-sm-btn mb-3 mr-3" : "mfs-blue-btn mfs-sm-btn mb-3 mr-3 not-active"}
            onClick={() => handleClick(item)}
          >
            {items[item]}
          </button>
        )})}
      </div>
    );
  }
  return (
    <div className='container py-5 font-medium'>
      {
        props.headerText &&
        <>
          <h3 className="mb-2 text-center font-bold">Not ready yet ?</h3>
          <h5 className="mb-2 pb-4 text-center font-regular">Explore our mortgage guides and calculators</h5>
        </>
      }
      {
        props.filterButton &&
        <FilterButtons active={activeFilter} handleClick={handleCategoryClick} />
      }
      {
        seoGuidePages && guidePagesFiltered &&
        <motion.div className="row font-regular"
          variants={boxVariant}
          animate="visible"
          initial="hidden"
        >
          {
            guidePagesFiltered.map((item, i) => {
              // console.log(item)
              if (i< showItems) return (
                <motion.div key={item.node.seoGuides.url} className='col-md-4 mb-4 pb-2 px-lg-4 mb-md-33 text-decoration-none' style={{'lineHeight': '23px'}}
                  variants={listVariant}
                >
                  <Link to={'/' + item.node.seoGuides.url} className='guides-link'>
                    <div className="guides-header" title={item.node.title}>{item.node.title}</div>
                    {
                      false &&
                      <div className='guides-img-container d-none'>
                        <img src={item.node.seoGuides?.image?.mediaItemUrl} className='img-fluid' alt={item.node.title} />
                      </div>
                    }
                  </Link>
                  <div className="guides-description">{item.node.seoGuides?.guideDescription}</div>
                  {/* <div className="guides-description">APRC refers to the annual percentage rate. As the name suggests, it is the rate of interest a lender charges for their loan. APRC refers to the annual percentage rate. As the name suggests, it is the rate of interest a lender charges for their loan</div> */}
                </motion.div>
              )
            })
          }
        </motion.div>
      }
      {
        props.moreLink &&
        <div className="text-center mt-4"><Link to="/tools/mortgage-guides" className='mfs-blue-btn'>See more guides...</Link></div>
      }
    </div>
  )
}
export default GuidesFilter;
