export const mainMenuData = (data) =>{
    return dispatch => {
        dispatch({
            type: "MAINMENU",
            data
        })
    }
}
export const isLoaded = (data) =>{
    return dispatch => {
        dispatch({
            type: "IS_LOADED",
            data
        })
    }
}
export const leadsData = (data) =>{
    return dispatch => {
        dispatch({
            type: "LEADS_DATA",
            data
        })
    }
}
export const setTrustpilot = (data) =>{
    return dispatch => {
        dispatch({
            type: "TRUSTPILOT",
            data
        })
    }
}
export const trustPilotReviews = (data) =>{
    return dispatch => {
        dispatch({
            type: "TRUSTPILOT_REVIEWS",
            data
        })
    }
}
export const siteMap = (data) =>{
    return dispatch => {
        dispatch({
            type: "SITEMAP",
            data
        })
    }
}
export const footerData = (data) =>{
    return dispatch => {
        dispatch({
            type: "FOOTER_DATA",
            data
        })
    }
}
export const servicesMenu = (data) =>{
    return dispatch => {
        dispatch({
            type: "SERVICES_MENU",
            data
        })
    }
}
export const footerMenu = (data) =>{
    return dispatch => {
        dispatch({
            type: "FOOTER_MENU",
            data
        })
    }
}
export const pageData = (data) =>{
    return dispatch => {
        dispatch({
            type: "PAGE_DATA",
            data
        })
    }
}
export const faqsData = (data) =>{
    return dispatch => {
        dispatch({
            type: "FAQS_DATA",
            data
        })
    }
}
export const lenderLogos = (data) =>{
    return dispatch => {
        dispatch({
            type: "LENDER_LOGOS",
            data
        })
    }
}
export const apiRates = (data) =>{
    return dispatch => {
        dispatch({
            type: "API_RATES",
            data
        })
    }
}
export const servicesName = (data) =>{
    return dispatch => {
        dispatch({
            type: "SERVICES_NAME",
            data
        })
    }
}
export const pluginApiRates = (data) =>{
    return dispatch => {
        dispatch({
            type: "PLUGIN_API_RATES",
            data
        })
    }
}
export const seoGuidePages = (data) =>{
    return dispatch => {
        dispatch({
            type: "SEO_GUIDE_PAGES",
            data
        })
    }
}
export const seoLocalPages = (data) =>{
    return dispatch => {
        dispatch({
            type: "SEO_LOCAL_PAGES",
            data
        })
    }
}
export const seoAdditionalBranchPages = (data) =>{
    return dispatch => {
        dispatch({
            type: "SEO_Additional_Branch_Pages",
            data
        })
    }
}