import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import axios from "axios";
import * as qs from 'qs';
import { getApiRatesDB } from '../../lib/api';

function LenderRateView(props) {
    const [activeRates, setActiveRates] = useState({});
    const [allRates, setAllRates] = useState({});
    const [rateList, setRateList] = useState(props.data);
    // console.log(props);
    let showRates = props.type;
    if (showRates === "buy-to-let") {
        showRates = "buy-to-let-purchase";
    }
    let servicesName = useSelector((state) => state.servicesName);

    // console.log(activeRates);
    let page_url = props.linkTo || '/tools/rates';

    useEffect(() => {
        const loadData = async () => {
            let data = props.data;
            // console.log(" props.lenderId - " + props.lenderId)
            // console.log(props)
            // data['lenderId'] = props.lenderId
            // data['type'] = props.type.value;
            // console.log(data)
            
            let response = await getApiRatesDB(data);
            // console.log(response)

            setAllRates({...response});

            // if (response) {
            //     let temp = {};
            //     temp['label'] = props.type.label;
            //     temp['value'] = props.type.value;
            //     temp['rate'] = response;
            //     console.log(temp)
            //     setActiveRates({ ...temp });
            // } else if (response == null) {
            //     let responseData = [];
            //     setActiveRates([...responseData]);
            // }
            // else {
            //     setActiveRates([]);
            // }
        }
        loadData();
    }, [props]);


    // useEffect(() => {
    //     fetchTopSavedRates();
    //     // console.log(" Active rates " + activeRates)
    // }, [fetchTopSavedRates]);
    // console.log(Object.keys(allRates).length, rateList, (rateList).length)
    return (
        <>
            {
                props.header ? 
                <h2 className="text-center font-bold my-4">{props.header}</h2> :
                <h2 className="text-center font-bold my-4">Best {activeRates.label} Rates</h2>
            }
            <div className="row justify-content-center mb-0">
                {Object.keys(allRates).length === 0 && [...Array(3).keys()].map((item, i) => {
                    return (
                        <div className="rates-api-container best-rates-api-container col-md-12" key={i}>
                            <div className="rate-group-view container-fluid1 py-30">
                                <div className="rate-group-view-container text-center mb-30">
                                    <div className="rate-group-row p-3 mb-30">
                                        <div className="row rate-api-row align-items-end">
                                            <div className="col-4 col-md">
                                                <div className="rate-value-title mh-auto">Lender</div>
                                                <Skeleton height="40px" />
                                            </div>
                                            <div className="col-4 col-md">
                                                <div className="rate-value-title mh-auto">Initial Rate</div>
                                                <Skeleton height="40px" />
                                            </div>
                                            <div className="col-4 col-md">
                                                <div className="rate-value-title mh-auto">APRC</div>
                                                <Skeleton height="40px" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <BestThreeRates allRates={allRates} servicesName={servicesName} />
                
                {false && (rateList).length > 0 && Object.keys(allRates).length>0 && rateList?.map((item, i) => {
                    let service_type = item.value;
                    let themeColor = '';
                    if (servicesName[service_type]) {
                        themeColor = (servicesName[service_type].services.themeColor);
                    }
                    let rateItem = allRates[item.value][50].slice(0,3);
                    console.log(rateItem);
                    // console.log('first-time-buyer'== service_type);
                    rateItem.map((itemRate, j) => {
                        // let rateSingleData = rateItem[j];
                        console.log(itemRate);
                        return(
                            <div className="rates-api-container best-rates-api-container col-md-12" key={j} style={{ "--theme-color": themeColor }}>
                                <div className="rate-group-view container-fluid1 py-30">
                                    <div className="rate-group-view-container text-center mb-30">
                                        <div className="rate-group-row px-3 py-1 mb-31">
                                            {/* <h4>{item.label}</h4> */}
                                            <div className="row rate-api-row align-items-end">
                                                <div className="col-4 col-md">
                                                    <div className="rate-value-title mh-auto">Lender Details</div>
                                                    <div className={`_${itemRate['LenderID']} mt-0 lender-logo`}></div>
                                                </div>
                                                <div className="col-4 col-md">
                                                    <div className="rate-value-title mh-auto">Initial Rate</div>
                                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '60px' }}>{itemRate['InitialRate']}%</div>
                                                </div>
                                                <div className="col-4 col-md">
                                                    <div className="rate-value-title mh-auto">APRC</div>
                                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '60px' }}>{parseInt(itemRate['APRC']).toFixed(2)}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                })}
            </div>
            <div className="text-center text-white1">Based on value of £200,000 at 50% LTV</div>
            <div className="row rate-api-row align-items-center pb-4 pt-2">
                <div className="col-md text-center">
                    <Link className={`btn1 btn-link1 text-white1`} to={page_url}>
                        <div className="">View all {activeRates.label} rates</div>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default LenderRateView;
function BestThreeRates({ allRates, servicesName }) {
    return (
        <>
            {Object.keys(allRates).length > 0 && Object.keys(allRates).map((item, i) => {
                let service_type = item;
                let themeColor = '';
                if (servicesName[service_type]) {
                    themeColor = (servicesName[service_type].services.themeColor);
                }
                let rateItem = allRates[item][50].slice(0, 3);
                return (
                    <div key={i} className='col-md-6 mb-4'>
                        <h5 className='text-center'>2 year fixed {servicesName[service_type]?.title} Rates</h5>
                        <div>
                            {rateItem.map((itemRate, j) => {
                                return (
                                    <div className="rates-api-container best-rates-api-container" key={j} style={{ "--theme-color": themeColor }}>
                                        <div className="rate-group-view container-fluid1 py-30">
                                            <div className="rate-group-view-container text-center mb-30">
                                                <div className="rate-group-row px-3 py-1 mb-31">
                                                    {/* <h4>{item.label}</h4> */}
                                                    <div className="row rate-api-row align-items-end">
                                                        <div className="col-4 col-md">
                                                            <div className="rate-value-title mh-auto">Lender</div>
                                                            <div className={`_${itemRate['LenderID']} mt-0 lender-logo`}></div>
                                                        </div>
                                                        <div className="col-4 col-md">
                                                            <div className="rate-value-title mh-auto">Initial Rate</div>
                                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '60px' }}>{itemRate['InitialRate']}%</div>
                                                        </div>
                                                        <div className="col-4 col-md">
                                                            <div className="rate-value-title mh-auto">APRC</div>
                                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '60px' }}>{parseInt(itemRate['APRC']).toFixed(2)}%</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
}