import React, { useState, useEffect } from 'react';
import { ImSpinner3 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import ModalDetails from './ModalDetails';
import {hexToRGB, LightenDarkenColor} from '../../lib/utils';

function APIView(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {

    }, []);
    // console.log(props.value);
    let servicesName = useSelector((state) => state.servicesName);
    let themeColor = '';
    if(servicesName[props.type]){
        // themeColor = hexToRGB(servicesName[props.type].services.themeColor, servicesName[props.type].services.themeOpacity/4);
        // themeColor = LightenDarkenColor(servicesName[props.type].services.themeColor, 190);
        themeColor = servicesName[props.type].services.themeColor;
    }
    return (
        <>
            <div className="rate-group-row p-3 mb-3 c-pointer" onClick={handleShow} style={{"--theme-color": themeColor}}>
                {/* <div className="rate-group-view container-fluid py-3"> */}
                    {/* <div className="rate-group-view-container text-center p-3"> */}
                        {/* <h5 className="font-bold">{props.value['LenderName']}</h5> */}
                        <div className="row rate-api-row align-items-end">
                            <div className="col-4 col-md-2">
                                <div className={`_${props.value['LenderID']} lender-logo`}></div>
                            </div>
                            <div className="col-4 col-md">
                                <div className="rate-value-title">Initial Rate</div>
                                {props.value['InitialRate']}%
                            </div>
                            <div className="col-4 col-md">
                                <div className="rate-value-title">Initial Period</div>
                                {props.value['InitialRateControlPeriod']}
                            </div>
                            <div className="col-4 pt-3 pt-md-0 col-md">
                                <div className="rate-value-title">Initial Payment</div>
                                £{parseInt(props.value['InitialMonthlyPayment']).toFixed(2)}
                            </div>
                            <div className="col-4 pt-3 pt-md-0 col-md-2">
                                <div className="rate-value-title">LTV</div>
                                {props.value['LTV']}%
                            </div>
                            <div className="col-4 pt-3 pt-md-0 col-md">
                                <div className="rate-value-title">Total Fees</div>
                                £{Number(props.value['TotalFees']).toFixed(2)}
                            </div>
                            <div className="col-md align-self-center">
                                <div className="btn btn-sm btn-link font-bold text-decoration-none" onClick={handleShow}>View Details</div>
                            </div>
                        </div>
                        {/* <div className="small">Last updated : {props.value['DateTime']}</div> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
            <Modal show={show} size="md" onHide={handleClose} className="rates-modal" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center" as="h5">
                        <div className={`_${props.value['LenderID']}`}></div>
                        {/* <div className="pl-2">{props.value['LenderName']}</div> */}
                        <div className="pl-2">{props.value['Note']}</div>
                    </Modal.Title>
                </Modal.Header>                        
                <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}><ModalDetails product={props.value} /></Modal.Body>
                <Modal.Footer>
                    <a href="tel:02080902043" className="font-weight-bold">Call us on 02080902043</a>
                    <Button variant="primary">Contact Us</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default APIView;
