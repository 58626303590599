import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function BuyToLet(props){
  const [propertyValue, setPropertyValue] = useState(5000); 
  const [deposit, setDeposit] = useState(1000); 
  const [result, setResult] = useState(0); 
  function handleChange(e){
    let value;
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      if(e.target.name==="propertyValue"){
        setPropertyValue(parseFloat(value));
      }
      if(e.target.name==="deposit"){
        setDeposit(parseFloat(value));
      }
    }
  }
  function calculate(){
    let i1 = propertyValue ? propertyValue : 0; 
    let i2 = (deposit < (propertyValue*0.2)) ? propertyValue*0.2 : deposit;
    let r = ((((parseInt(i1)-i2)*0.06)/12).toFixed(2));
    setResult(r);
  }
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [propertyValue, deposit]);
  return (
    <>
      <div className="calculator-wrapper my-31">
        <div className="range-container">
          <h4 className="text-center">Buy-to-let rental income calculator</h4>
          <div className="text-center d-none">Our Buy-to-let calculator is a great first step for anyone who's thinking of investing in a rental property <br/>Again, many lenders have different requirements and therefore no calculator will ever cater for all such variations <br/>This calculator is design to give you a rough Idea. If you need something more accurate, please contact us so that a qualified mortgage advisor and can assess and advise</div>
          <div className="new-label-input"> 
            <div className="option-label d-flex">
            <div className="option-label-text ml-2 px-1">Property Value:</div>
              <div className="range-value-container px-1">
                £ <span className="range-value">{AddComma(propertyValue)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="text" name="propertyValue"
                value={propertyValue} onChange={handleChange} />
            </div>
            <div className="option-label d-flex">
            <div className="option-label-text ml-2 px-1">Deposit Value:</div>
              <div className="range-value-container px-1">
                £ <span className="range-value">{AddComma(deposit)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="text" name="deposit"
                value={deposit} onChange={handleChange} />
            </div>
          </div>
          <div>Result : £{AddComma(result)}</div>
        </div>
      </div>
    </>
  );
}
export default BuyToLet;