const initial = {};
const pluginApiRates = (state = initial, action)=>{
    switch(action.type){
        case 'PLUGIN_API_RATES':
            return state = action.data;
        default: 
            return state;
    }
}

export default pluginApiRates;