import React from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import ContactCTA from './ContactCTA';
// import { FaUser } from "react-icons/fa";

function NewBanner(props){
  // console.log(props);
  let mainHeading = props.heading;
  let bannerImage = props.bannerImage || 'https://api.mariannafs.co.uk/wp-content/uploads/2023/10/Books.png';
  // let bannerImage = props.bannerImage;
  let postDate = props.postDate;
  let authorName = props.authorName;
  let subText = props.subText;
  let authorPhoto = useSelector((state) => state.footerData)?.trustpilotExtras?.authorPhoto?.sourceUrl || 'https://api.mariannafs.co.uk/wp-content/uploads/2023/10/Milton-Pic-scaled.jpg';
  // console.log(useSelector((state) => state.footerData));
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  }
  return (
    <div className="new-banner-container">
      <div className={`new-banner-section max-1200 mx-auto ${!props.noRatingsSection?'':'pb-0'}`}>
        <div className="container max-1000">
          <div className="row">
            <div className={`${props.noBannerImage?'col-md-12':'col-md-8'}`}>
              {
                mainHeading && 
                <h1 className='h2 font-bold' dangerouslySetInnerHTML={{ __html: mainHeading }}></h1>
              }
              {
                authorName &&
                <div className='d-flex font-regular align-items-center my-5'>
                  <div className='d-flex align-items-center pr-4'>
                    {/* <FaUser color="#227200" className='mr-2' /> */}
                    <div className='mr-2 rounded-circle' style={{'width':'70px','height':'70px', 'backgroundImage': 'url('+authorPhoto+')', 'backgroundSize': 'cover', 'backgroundPosition': 'center', 'aspectRatio': '1/1'}}></div>
                    {/* <img src={authorPhoto} alt="" width={100} className='mr-2' /> */}
                    {authorName}
                  </div>
                  <div>Updated on {formatDate(postDate)}</div>
                </div>                
              }
              {
                subText &&
                <div className='d-flex font-regular align-items-center my-4'>
                  <div className='' dangerouslySetInnerHTML={{ __html: subText }}></div>
                </div>                
              }
            </div>
            <div className={`${props.noBannerImage?'d-none':'col-md-4'}`}>
              <img src={bannerImage} alt={mainHeading} className='img-fluid' style={{'maxWidth':'230px'}} />
            </div>
          </div>
        </div>
      </div>
      {
        !props.noRatingsSection &&
        <ContactCTA hideHeader={true} containerClasses="max-700 banner-cta pt-2" ratingHeader={true} />
      }
    </div>      
  );
}
export default NewBanner;
