const initial = {};
const servicesName = (state = initial, action)=>{
    switch(action.type){
        case 'SERVICES_NAME':
                return state = action.data;
        default: 
            return state;
    }
}

export default servicesName;